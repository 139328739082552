export default function BlueAccountsWithGearIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 31 25"
    >
      <path fill="#60A5FA" d="M3 0a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h8V0H3Z" />
      <path fill="#2563EB" d="M19 22a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3h-8v22h8Z" />
      <path
        fill="#A0CBFF"
        d="M8.09 18.52a.75.75 0 0 0 1.5 0h-1.5ZM9.59 4a.75.75 0 0 0-1.5 0h1.5ZM3.25 18.52a.75.75 0 0 0 1.5 0h-1.5Zm1.5-3.63a.75.75 0 0 0-1.5 0h1.5Zm8.18 3.63a.75.75 0 0 0 1.5 0h-1.5Zm1.5-10.89a.75.75 0 0 0-1.5 0h1.5ZM9.59 18.52V4h-1.5v14.52h1.5Zm-4.84 0v-3.63h-1.5v3.63h1.5Zm9.68 0V7.63h-1.5v10.89h1.5Z"
      />
      <path
        fill="#60A5FA"
        d="M12.93 18.52a.75.75 0 0 0 1.5 0h-1.5Zm1.5-10.9a.75.75 0 0 0-1.5 0h1.5Zm0 10.9V7.62h-1.5v10.9h1.5ZM28.15 17.53c0-.15 0-.32-.02-.47L30 15.95l-2.42-4.32-1.89 1.13c-.25-.18-.5-.33-.77-.46V10h-4.84v2.3c-.27.13-.52.28-.77.46l-1.9-1.13L15 15.95l1.87 1.11c-.02.15-.02.32-.02.47 0 .15 0 .31.02.46L15 19.11l2.42 4.31 1.89-1.13c.25.19.5.34.77.47V25h4.84v-2.24c.27-.13.52-.28.77-.47l1.9 1.13L30 19.11l-1.87-1.12c.02-.15.02-.31.02-.46Zm-5.65 2.49a2.45 2.45 0 0 1-2.42-2.5c0-1.37 1.08-2.48 2.42-2.48a2.45 2.45 0 0 1 2.42 2.49c0 1.38-1.08 2.49-2.42 2.49Z"
      />
      <path
        fill="#2563EB"
        d="m28.33 18 1.97 1.1-2.54 4.32-1.99-1.13a5.7 5.7 0 0 1-.82.47V25h-2.54v-4.98c1.4 0 2.54-1.11 2.54-2.5 0-1.37-1.13-2.48-2.54-2.48V10h2.54v2.3c.3.13.55.28.82.46l1.99-1.13 2.54 4.32-1.97 1.11c.02.15.02.32.02.47 0 .15 0 .31-.02.46Z"
      />
    </svg>
  );
}
