import { Dispatch, SetStateAction } from "react";
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { useAppDispatch } from "../../../hooks";
import { ResponseStatusType } from "../../../types/details";
import { KEY_LENGTH } from "../../../const";
import { setLoadAmountToStartStatus } from "../../../store/actions/start";
import { fetchAmountToStart } from "../../../store/api-actions/start";

type Step2FieldsProps = {
  accountName: string;
  loadAmountToStartStatus: null | ResponseStatusType;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  isKeyLengthError: boolean;
  setKeyLengthError: Dispatch<SetStateAction<boolean>>;
};

export default function Step2Fields({
  accountName,
  loadAmountToStartStatus,
  getValues,
  register,
  isKeyLengthError,
  setKeyLengthError,
}: Step2FieldsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const isKeysSuccess = loadAmountToStartStatus?.isSuccess;
  const keyDisabledClass = isKeysSuccess ? "field--disabled" : "";

  // handleKeyChange
  const handleKeyChange = () => {
    const data = getValues(["key", "secret"]);
    const [key, secret] = data;

    if (key.length === KEY_LENGTH && secret.length === KEY_LENGTH) {
      setKeyLengthError(false);
      setTimeout(() => {
        dispatch(fetchAmountToStart({ id: accountName, key, secret }));
      }, 500);
      return;
    }

    if (key && secret) {
      setKeyLengthError(true);
      if (loadAmountToStartStatus) {
        dispatch(setLoadAmountToStartStatus(null));
      }
      return;
    }

    if (isKeyLengthError) {
      setKeyLengthError(false);
    }

    if (loadAmountToStartStatus) {
      dispatch(setLoadAmountToStartStatus(null));
    }
  };

  //

  return (
    <div className="connect-step__fields-inner-wrapper">
      {/* api-key */}
      <label className="visually-hidden" htmlFor="api-key">
        Api key
      </label>
      <input
        className={`connect-step__field connect-step__field--api-key field 
            ${keyDisabledClass}`}
        type="text"
        id="api-key"
        placeholder="API Key"
        autoComplete="off"
        {...register("key", { required: true, onChange: handleKeyChange })}
      />

      {/* secret-key */}
      <label className="visually-hidden" htmlFor="secret-key">
        Secret key
      </label>
      <input
        className={`connect-step__field connect-step__field--secret-key field 
            ${keyDisabledClass}`}
        type="text"
        id="secret-key"
        placeholder="Secret Key"
        autoComplete="off"
        {...register("secret", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
    </div>
  );
}
