import { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { useForm } from "react-hook-form";

import Button from "../../button/button";
import ConnectPoint2 from "../points/connect-point2";
import Step2Fields from "../fields/step2-fileds";
import Step2Message from "../messages/step2-message";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { KeyValuesType } from "../../../types/details";
import { ConnectSteps } from "../../../const";
import { setLoadAmountToStartStatus } from "../../../store/actions/start";

type ConnectStep2Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  setKeyValues: Dispatch<SetStateAction<null | KeyValuesType>>;
};

export default function ConnectStep2({
  pointId,
  activeStep,
  setActiveStep,
  setKeyValues,
}: ConnectStep2Props): JSX.Element {
  const dispatch = useAppDispatch();
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);

  const [isKeyLengthError, setKeyLengthError] = useState<boolean>(false);
  const [isFirstTimeNext, setFirstTimeNext] = useState<boolean>(true);

  const {
    strategyDetails,
    loadAmountToStartStatus,
    isLoadAmountToStartLoading,
  } = useAppSelector((state) => state);
  const accountName = strategyDetails?.overview?.code as string;

  const { register, getValues, setFocus, setValue } = useForm({
    mode: "onChange",
  });

  const isLoadAmountSuccess = loadAmountToStartStatus?.isSuccess;
  const isLoadAmountFailed = loadAmountToStartStatus?.isSuccess === false;
  const isStepActive = activeStep === ConnectSteps.STEP2;
  const isStepDone = activeStep === ConnectSteps.STEP3;
  const blockClass = isStepActive ? "block--active" : "block--disabled";
  const nextDisabledClass = isLoadAmountSuccess ? "" : "btn--disabled";
  const backDisabledClass = isLoadAmountToStartLoading ? "btn--disabled" : "";
  const isPointActive = isStepActive || isStepDone;

  //
  const clearKeys = () => {
    setValue("key", "");
    setValue("secret", "");
  };

  const handleBackClick = () => {
    setActiveStep(ConnectSteps.STEP1);

    if (isKeyLengthError) {
      setKeyLengthError(false);
      clearKeys();
    }

    if (isLoadAmountFailed) {
      dispatch(setLoadAmountToStartStatus(null));
      clearKeys();
    }
  };

  const handleNextClick = () => {
    setActiveStep(ConnectSteps.STEP3);
  };

  useEffect(() => {
    // Переход на 3й блок, если получаем успешные ключи
    if (isLoadAmountSuccess && isFirstTimeNext) {
      const data = getValues(["key", "secret"]);
      const [key, secret] = data;

      setActiveStep(ConnectSteps.STEP3);
      setKeyValues({ key, secret });
      setFirstTimeNext(false);
    }

    // Фокус кнопку Next, если был ex возврат на 1й блок после успешных ключей
    if (isLoadAmountSuccess && nextBtnRef.current && isStepActive) {
      nextBtnRef.current.focus();
    }
  }, [
    getValues,
    isFirstTimeNext,
    isLoadAmountSuccess,
    isStepActive,
    setActiveStep,
    setKeyValues,
  ]);

  // Фокус на первое поле, если 2й блок становится активным и не было успешных ключей
  useEffect(() => {
    if (isStepActive && !isLoadAmountSuccess) {
      setFocus("key");
    }
  }, [isStepActive, isLoadAmountSuccess, setFocus]);

  return (
    <form
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">Enter API key and&nbsp;Secret&nbsp;key</h3>
      <p className="connect-step__intro">
        Go back to Binance, copy both API keys and paste them into the fields
        below.
      </p>
      <div className="connect-step__fields-wrapper">
        <Step2Fields
          accountName={accountName}
          loadAmountToStartStatus={loadAmountToStartStatus}
          getValues={getValues}
          register={register}
          isKeyLengthError={isKeyLengthError}
          setKeyLengthError={setKeyLengthError}
        />

        <div className="connect-step__message">
          {(isKeyLengthError || isLoadAmountFailed) && (
            <Step2Message
              isKeyLengthError={isKeyLengthError}
              loadAmountToStartStatus={loadAmountToStartStatus}
            />
          )}
        </div>
      </div>

      {/* buttons */}
      <div className="connect-step__btn-wrapper">
        <Button
          classElement={backDisabledClass}
          pattern="blue-white"
          text="Back"
          icon="arrow-left"
          type="button"
          handleClick={handleBackClick}
        />
        <Button
          classElement={nextDisabledClass}
          pattern="white-blue"
          text="Next"
          icon={isLoadAmountToStartLoading ? "arrow-loader" : "arrow-right"}
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
      </div>
      <ConnectPoint2 pointId={pointId} isPointActive={isPointActive} />
    </form>
  );
}
