import { useEffect } from "react";

import BinancePromo from "../../components/promos/binance-promo";
import Bots from "../../components/bots/bots";
import BinancePluses from "../../components/pluses/binance-pluses";
import BinanceFaq from "../../components/faq/binance-faq";
import BinanceBriefly from "../../components/brieflies/binance-briefly";
import BinanceSteps from "../../components/steps/binance-steps";
import CompareBlocks from "../../components/compare-blocks/compare-blocks";
import DeversifyBlock from "../../components/deversify-block/deversify-block";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { trackEvent } from "../../utils/tracking";
import { deleteSeoData } from "../../utils/general";
import { BinanceLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import {
  fetchBinanceFuturesDemo,
  fetchBinanceSpotDemo,
  fetchDiversifiedDemo,
  fetchOkxDemo,
  fetchBybitDemo,
} from "../../store/api-actions/demo";

export default function BinanceScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { binanceSpotDemo, binanceFuturesDemo, diversifiedDemo } =
    useAppSelector((state) => state);

  const binanceSpotQuotes = binanceSpotDemo?.quotes;
  const binanceFuturesQuotes = binanceFuturesDemo?.quotes;

  // any page
  useEffect(() => {
    trackEvent("BinanceLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchOkxDemo());
    dispatch(fetchBinanceSpotDemo());
    dispatch(fetchBinanceFuturesDemo());
    dispatch(fetchBybitDemo());
    dispatch(fetchDiversifiedDemo());
  }, [dispatch]);

  return (
    <main className="main main--landing landing">
      <BinanceLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <BinancePromo />
        {binanceSpotQuotes && binanceFuturesQuotes && (
          <CompareBlocks
            binanceSpotQuotes={binanceSpotQuotes}
            binanceFuturesQuotes={binanceFuturesQuotes}
          />
        )}
        {diversifiedDemo && binanceSpotQuotes && binanceFuturesQuotes && (
          <DeversifyBlock />
        )}
        <BinancePluses />
        <BinanceSteps />
        <BinanceBriefly />
        <Bots />
        <BinanceFaq />
      </div>
    </main>
  );
}
