import { useEffect } from "react";

import BitgetPromo from "../../components/promos/bitget-promo";
import BitgetPluses from "../../components/pluses/bitget-pluses";
import Bots from "../../components/bots/bots";
import BitgetInsight from "../../components/insights/bitget-insight";
import BitgetSteps from "../../components/steps/bitget-steps";
import BitgetFaq from "../../components/faq/bitget-faq";
import BitgetBriefly from "../../components/brieflies/bitget-briefly";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteSeoData } from "../../utils/general";
import { BitgetLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { fetchBitgetFuturesDemo } from "../../store/api-actions/demo";

export default function BitgetScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { bitgetFuturesDemo } = useAppSelector((state) => state);
  // any page
  useEffect(() => {
    trackEvent("BitgetLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchBitgetFuturesDemo());
  }, [dispatch]);

  return (
    <main className="main main--landing landing">
      <BitgetLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <BitgetPromo />
        <BitgetPluses />
        <BitgetSteps />
        {bitgetFuturesDemo && <BitgetInsight />}
        <BitgetBriefly />
        <Bots />
        <BitgetFaq />
      </div>
    </main>
  );
}
