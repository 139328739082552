export default function TrendingDownIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill="currentColor"
        d="m11.8 9.27 1.18-1.25-4-4.2L6.3 6.64a.78.78 0 0 1-1.16 0L.24 1.47a.9.9 0 0 1 0-1.22.78.78 0 0 1 1.15 0l4.33 4.57L8.4 2a.78.78 0 0 1 1.15 0l4.57 4.81 1.17-1.24c.26-.27.7-.08.7.3v3.7a.4.4 0 0 1-.4.44h-3.5c-.38 0-.56-.47-.3-.73Z"
      />
    </svg>
  );
}
