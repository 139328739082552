import { useAppDispatch } from "../../../../hooks";
import { changePositionModeBybit } from "../../../../store/api-actions/start";

export default function BybitChangeModeNote(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleLinkClick = () => {
    dispatch(changePositionModeBybit());
  };

  return (
    <span className="message__note">
      Click{" "}
      <button
        className="message__link link link--underline"
        onClick={handleLinkClick}
        type="button"
      >
        here
      </button>{" "}
      to automatically change position mode to enable trading
      in&nbsp;your&nbsp;account
    </span>
  );
}
