import { memo } from "react";
import OverviewBoard from "./overview-board";
import { useAppSelector } from "../../hooks/index";
import {
  getSortedByBtn,
  sortOverviewByStatusAndProfit,
} from "../../utils/component/overview";

function OverviewList(): JSX.Element {
  const { strategies } = useAppSelector((state) => state);

  return (
    <ul className="overview__list">
      {strategies.length !== 0 &&
        getSortedByBtn(strategies)
          .sort(sortOverviewByStatusAndProfit)
          .map(
            (item, index): JSX.Element => (
              <OverviewBoard
                key={index}
                overview={item}
                index={index}
                strategiesLength={strategies.length}
              />
            )
          )}
    </ul>
  );
}

export default memo(OverviewList);
