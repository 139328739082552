import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../button/button";
import OkxStep3MessageConnected from "../../messages/okx-step3-message-connected";
import ConnectPoint3 from "../../points/connect-point3";
import OkxStep3Fields from "../../fields/okx-step3-fields";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { minNotional } from "../../../../utils/env";
import { OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE } from "../../../../const";
import {
  fetchAmountToStartOkx,
  sendAmountToStartOkx,
} from "../../../../store/api-actions/start";
import {
  loadAmountToStart,
  setChangePositionModeStatus,
  setCheckPositionModeStatus,
  setLoadAmountToStartStatus,
  setSendAmountToStartStatus,
} from "../../../../store/actions/start";

export default function OkxConnectStep3Connected(): JSX.Element {
  const dispatch = useAppDispatch();
  const startBtnRef = useRef<HTMLButtonElement | null>(null);
  const [isShowMessage, setShowMessage] = useState<boolean>(false);

  const {
    amountToStart,
    isSendAmountForStartLoading,
    sendAmountToStartStatus,
    isChangePositionModeLoading,
    isLoadAmountToStartLoading,
    changePositionModeStatus,
    loadAmountToStartStatus,
    checkPositionModeStatus,
    isCheckPositionModeLoading,
  } = useAppSelector((state) => state);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setFocus,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isSendAmountFail = sendAmountToStartStatus?.isSuccess === false;
  const isChangeModeSuccess = changePositionModeStatus?.isSuccess;
  const isLoadAmountSuccess = loadAmountToStartStatus?.isSuccess;
  const isSendAmountFailWithNote =
    sendAmountToStartStatus?.code ===
    OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE;
  const isLoading =
    isCheckPositionModeLoading ||
    isSendAmountForStartLoading ||
    isChangePositionModeLoading ||
    isLoadAmountToStartLoading;
  const availableAmount = Math.floor(amountToStart?.usdt as number);
  const submitDisabledClass =
    !isValid ||
    isLoading ||
    isChangeModeFail ||
    isSendAmountFailWithNote ||
    isCheckModeFail ||
    isLoadAmountFail
      ? "btn--disabled"
      : "";

  // onSubmit
  const onSubmit = () => {
    const data = getValues(["amount"]);
    const [amount] = data;
    dispatch(
      sendAmountToStartOkx({
        amount,
        currency: "usdt",
      })
    );
  };

  // Действия при появлении блока
  useEffect(() => {
    setFocus("amount");
    setValue("amount", availableAmount);
    trigger("amount");
  }, [availableAmount, setFocus, setValue, trigger]);

  // Обнуление статуса запросов при успехе на change-mode,
  // чтобы корректно запросить новый на start и убрать сообщение у check-mode и сhange-mode
  useEffect(() => {
    if (changePositionModeStatus) {
      dispatch(setCheckPositionModeStatus(null));
    }

    if (isChangeModeSuccess) {
      dispatch(setChangePositionModeStatus(null));
    }

    if (isChangeModeSuccess && sendAmountToStartStatus) {
      // случай когда при запросе start пришел код 49999, далее успешное change-mode
      dispatch(setSendAmountToStartStatus(null));
    }
  }, [
    dispatch,
    checkPositionModeStatus,
    isChangeModeSuccess,
    sendAmountToStartStatus,
    changePositionModeStatus,
  ]);

  // Сразу запрос на coin-info
  useEffect(() => {
    dispatch(fetchAmountToStartOkx());
  }, [dispatch]);

  // Для отображения первого сообщения
  useEffect(() => {
    if (isLoadAmountFail || (isLoadAmountSuccess && checkPositionModeStatus)) {
      setShowMessage(true);
    }
  }, [checkPositionModeStatus, isLoadAmountFail, isLoadAmountSuccess]);

  // Убирать фокус с кнопки Start, если ошибка запуска
  useEffect(() => {
    if (isSendAmountFail && startBtnRef.current) {
      startBtnRef.current.blur();
    }
  }, [isSendAmountFail]);

  useEffect(() => {
    return () => {
      dispatch(loadAmountToStart(null));
      dispatch(setLoadAmountToStartStatus(null));
      dispatch(setCheckPositionModeStatus(null));
      dispatch(setChangePositionModeStatus(null));
      dispatch(setSendAmountToStartStatus(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <form
      className="details__item connect-step block block--step"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 className="block__heading">
        Specify the amount to&nbsp;be&nbsp;traded&nbsp;by&nbsp;SMARD
      </h3>
      <p className="connect-step__intro">
        {`We will only use the amount you specify for trading. Minimum ${minNotional} USDT.`}
      </p>
      <div className="connect-step__fields-wrapper">
        <OkxStep3Fields
          availableAmount={availableAmount}
          isLoading={isLoading}
          setValue={setValue}
          trigger={trigger}
          register={register}
        />
        <div className="connect-step__message">
          {isShowMessage && (
            <OkxStep3MessageConnected
              availableAmount={availableAmount}
              sendAmountToStartStatus={sendAmountToStartStatus}
              changePositionModeStatus={changePositionModeStatus}
              loadAmountToStartStatus={loadAmountToStartStatus}
              checkPositionModeStatus={checkPositionModeStatus}
            />
          )}
        </div>
      </div>

      <div className="connect-step__btn-wrapper">
        <Button
          classElement={submitDisabledClass}
          pattern="white-blue"
          text="Start"
          icon={isLoading ? "arrow-loader" : "arrow-right"}
          type="submit"
          ref={startBtnRef}
        />
      </div>
      <ConnectPoint3 pointId="" isPointActive={false} />
    </form>
  );
}
