export default function PercentUpIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.5 13.48c1.12-.47 1.7.5.87 1.22a8 8 0 1 1-.01-13.42l.02.01c.98.65.24 1.94-.84 1.26l-.04-.03a6.5 6.5 0 1 0 0 10.96Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m13 7.73 2.36-4.59c.04-.07.09-.1.14-.1.05 0 .1.03.14.1l2.25 4.65.05.2c0 .07 0 .15-.02.22a.44.44 0 0 1-.07.17c-.03.04-.07.07-.1.07l-.97-.02-.05 3.85c0 .2-.04.4-.12.54-.07.14-.17.22-.27.22l-1.92-.02c-.1 0-.2-.08-.27-.23-.07-.14-.11-.34-.11-.54l.05-3.85-.96-.01c-.04 0-.08-.02-.11-.07a.44.44 0 0 1-.07-.17.79.79 0 0 1 0-.22c0-.08.02-.15.05-.2ZM5.3 4c.55 0 1 .21 1.33.64.34.43.5 1.03.5 1.8s-.15 1.37-.47 1.8c-.31.44-.77.66-1.36.66-.54 0-.98-.22-1.3-.65a2.94 2.94 0 0 1-.5-1.81c0-.77.15-1.37.46-1.8.31-.43.76-.64 1.34-.64Zm.02 1.3c-.14 0-.24.1-.3.32-.04.2-.07.48-.07.84 0 .35.03.63.08.84.05.21.15.32.29.32.14 0 .23-.1.28-.31.05-.21.08-.5.08-.85 0-.36-.03-.64-.08-.84-.05-.21-.14-.32-.28-.32Zm5.33-1.19-3.9 7.8H5.3l3.91-7.8h1.43Zm.01 3c.56 0 1 .21 1.33.64.34.42.51 1.02.51 1.8 0 .76-.16 1.36-.47 1.8-.32.43-.77.65-1.37.65-.54 0-.97-.22-1.3-.65a2.93 2.93 0 0 1-.5-1.8c0-.78.16-1.38.47-1.8.3-.43.75-.64 1.33-.64Zm.02 1.3c-.14 0-.23.1-.29.31-.05.21-.07.5-.07.85s.02.63.07.84c.06.21.15.31.3.31.13 0 .22-.1.28-.3.05-.21.08-.5.08-.85 0-.37-.03-.66-.08-.86-.06-.2-.15-.3-.29-.3Z"
      />
    </svg>
  );
}
