import { useState } from "react";
import PricingSlider, { PricingSliderTypes } from "./pricing-slider";
import PricingSliderAmount from "./pricing-slider-amount";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import { setAuthModalOpened } from "../../store/actions";
import { AppRoute, AuthStatus } from "../../const";
import { useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";

type PricingCalculatorSectionProps = {
  isMobile: boolean;
};

function PricingCalculatorSection({ isMobile }: PricingCalculatorSectionProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authStatus, accountStatuses } = useAppSelector((state) => state);
  const isTradingStarted = accountStatuses?.already_launched;

  const [initialAmount, setInitialAmount] = useState<number>(10000);
  const [performance, setPerformance] = useState<number>(5);

  const calculateAmount = (base: number, percent: number) =>
    Math.round(base * (percent / 100));

  const displayProfit = () =>
    `${Math.abs(calculateAmount(initialAmount, performance))} USDT`;

  const displayServiceFee = () => {
    const serviceFee =
      performance <= 0 ? 1 : calculateAmount(initialAmount, performance) * 0.1;
    return `${Math.round(serviceFee)} USDT`;
  };

  const handleStartClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
  };

  const handleViewClick = () => {
    navigate(AppRoute.Accounts);
  };

  return (
    <section className="landing__item">
      <h2 className="landing__title">
        {isMobile ? "How Commission is Calculated" : "Fee Calculator"}
      </h2>
      <div className="pricing-slider">
        <div className="pricing__sliders-container">
          <PricingSlider
            setValue={setInitialAmount}
            value={initialAmount}
            type={PricingSliderTypes.AMOUNT}
          />
          <PricingSlider
            setValue={setPerformance}
            value={performance}
            type={PricingSliderTypes.FEES}
          />
        </div>
        <div className="pricing__amounts-container">
          <PricingSliderAmount
            isLoss={performance <= 0}
            valueToDisplay={displayProfit()}
            type={PricingSliderTypes.AMOUNT}
          />
          <PricingSliderAmount
            isLoss={performance <= 0}
            valueToDisplay={displayServiceFee()}
            type={PricingSliderTypes.FEES}
          />
        </div>
      </div>
      <div className="pricing__slider-button-group">
        <p className="pricing__note">
          * As of the day you began using the service on your account, SMARD
          charges a monthly fee for the use of trading software. If multiple
          accounts are connected, then every account will have its own day for
          payment. If the service is not paid, trading on all accounts will be
          paused.
        </p>
        {isTradingStarted ? (
          <Button
            classElement="demo-insight__btn"
            pattern="white-blue"
            text="View details"
            type="button"
            icon="eye"
            handleClick={handleViewClick}
          />
        ) : (
          <Button
            classElement="demo-insight__btn"
            pattern="white-blue"
            text="Start now"
            icon="arrow-right"
            type="button"
            isSelfLink
            handleClick={handleStartClick}
          />
        )}
      </div>
    </section>
  );
}

export default PricingCalculatorSection;
