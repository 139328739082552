import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import Copy from "../../copy/copy";
import Button from "../../button/button";
import ConnectHelp from "../general/connect-help";
import ConnectPoint1 from "../points/connect-point1";
import ConnectApiLink from "../general/connect-api-link";
import { useAppSelector } from "../../../hooks";
import { ConnectSteps, IP_ADDRESS } from "../../../const";

type ConnectStep1Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

export default function ConnectStep1({
  pointId,
  activeStep,
  setActiveStep,
  isStepsAnimationEnd,
}: ConnectStep1Props): JSX.Element {
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);
  const { strategyDetails } = useAppSelector((state) => state);

  const accountName = strategyDetails?.overview?.code as string;

  const isStepActive = activeStep === ConnectSteps.STEP1;
  const blockClass = `${isStepActive ? "block--active" : "block--disabled"}`;

  const handleNextClick = () => {
    setActiveStep(ConnectSteps.STEP2);
  };

  // Фокус на кнопку Next как только закончилась анимация, и не демо
  useEffect(() => {
    if (nextBtnRef.current && isStepActive && isStepsAnimationEnd) {
      nextBtnRef.current.focus();
    }
  }, [isStepActive, isStepsAnimationEnd]);

  return (
    <div
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">
        Log in to Binance and&nbsp;create&nbsp;API&nbsp;keys
      </h3>
      <p className="connect-step__intro">
        Go to {<ConnectApiLink accountName={accountName} />} on Binance and
        create API with the&nbsp;following&nbsp;restrictions:
      </p>
      <ul className="connect-step__list">
        <li className="connect-step__item">Enable Spot & Margin Trading;</li>
        <li className="connect-step__item">
          <span className="connect-step__item-text">
            Restrict access to trusted IPs only:
          </span>
          <Copy text={IP_ADDRESS} />
        </li>
      </ul>
      <div className="connect-step__step1-btn-wrapper">
        <Button
          pattern="white-blue"
          text="Next"
          icon="arrow-right"
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
        <ConnectHelp accountName={accountName} />
      </div>
      <ConnectPoint1 pointId={pointId} accountName={accountName} />
    </div>
  );
}
