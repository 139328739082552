import { useEffect, useState } from "react";

import Promo from "../../components/promos/promo";
import WhyWorksSteps from "../../components/pluses/why-works-steps";
import Exchanges from "../../components/grid/exchanges";
import Start from "../../components/steps/start";
import End from "../../components/end/end";
import HowWorksSteps from "../../components/pluses/how-works-steps";
import CalcBlock from "../../components/calc-block/calc-block";
import Publishers from "../../components/grid/publishers";
import Reviews from "../../components/reviews/reviews";
import RelativeBlock from "../../components/relative-block/relative-block";
import MainFaq from "../../components/faq/main-faq";
import ScrammersNote from "../../components/scrammers-note/scrammers-note";
import { ManualSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteSeoData } from "../../utils/general";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { fetchCalcChart } from "../../store/api-actions";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import {
  fetchBinanceFuturesDemo,
  fetchBinanceSpotDemo,
  fetchBitgetFuturesDemo,
  fetchOkxDemo,
  fetchBybitDemo,
} from "../../store/api-actions/demo";

export default function ManualScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { calcChart } = useAppSelector((state) => state);

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  useEffect(() => {
    dispatch(fetchCalcChart());
    dispatch(fetchOkxDemo());
    dispatch(fetchBinanceSpotDemo());
    dispatch(fetchBinanceFuturesDemo());
    dispatch(fetchBybitDemo());
    dispatch(fetchBitgetFuturesDemo());
  }, [dispatch]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);
  //

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  return (
    <main className="main main--landing landing">
      <ManualSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        {isTabletAndMore || <ScrammersNote />}

        <Promo />
        <div className="landing__relative-gap">
          <RelativeBlock />
        </div>
        {/*<Exchanges />*/}
        <WhyWorksSteps />
        {calcChart && !isTabletAndMore && <CalcBlock chart={calcChart} />}
        <Start />
        <HowWorksSteps />
        <Reviews />
        <Publishers />
        <End />
        <MainFaq />
      </div>
    </main>
  );
}
