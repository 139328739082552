import { StrategyOverviewType } from "../../../types/details";
import { displayStrategyAmount } from "../../../utils/component/overview";

type DetailsCurrentProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
};

export default function DetailsCurrent({
  strategyStatus,
  isTabletAndMore,
  overview,
}: DetailsCurrentProps): JSX.Element {
  const amount = overview.under_management;
  const currency = overview.currency;

  return currency && amount !== null ? (
    <div className="details-board__total details-board__total--current">
      <p className="details-board__amount">
        {displayStrategyAmount(
          "current",
          strategyStatus,
          isTabletAndMore,
          amount,
          currency
        )}
      </p>
      <span className="details-board__text">Current balance</span>
    </div>
  ) : (
    <></>
  );
}
