import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import RelativeCanvas from "../canvases/relative-canvas";
import { debounce, sortDemoByProfit } from "../../utils/chart/chart";
import { QuotesItemType } from "../../types/details";
import { AccountName, RERENDER_DELAY } from "../../const";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { findRelativeCorrelativeMinMax } from "../../utils/chart/details-chart";
import { DemoType, DemoTypeMap, SelectedAccountNames } from "../../types/general";

type RelativeBlockSlidersProps = {
  binanceSpotData: DemoType;
  binanceFuturesData: DemoType;
  bitgetFuturesData: DemoType;
  okxData: DemoType;
  bybitData: DemoType;
};

export default function RelativeBlockSliders({
  binanceSpotData,
  binanceFuturesData,
  bitgetFuturesData,
  okxData,
  bybitData,
}: RelativeBlockSlidersProps): JSX.Element {
  const MOST_POPULAR_ACCOUNT = AccountName.Bybit;
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );
  const [sortedChartAccounts, setSortedChartAccounts] = useState<AccountName[]>([]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const chartsDataMap: DemoTypeMap = {
    [AccountName.BinanceSpot]: binanceSpotData,
    [AccountName.Bybit]: bybitData,
    [AccountName.BitgetFutures]: bitgetFuturesData,
    [AccountName.Okx]: okxData,
    [AccountName.BinanceFutures]: binanceFuturesData,
  };

  const chartAccounts: SelectedAccountNames[] = [
    AccountName.BinanceSpot,
    AccountName.Bybit,
    AccountName.BitgetFutures,
    AccountName.Okx,
    AccountName.BinanceFutures,
  ];

  const chartAccountsInMobile: SelectedAccountNames[] = [
    AccountName.BinanceSpot,
    AccountName.Bybit,
    AccountName.BitgetFutures,
    AccountName.Okx,
    AccountName.BinanceFutures,
  ];

  const chooseData = (account: string) => {
    switch (account) {
      case AccountName.BinanceSpot:
        return binanceSpotData;
      case AccountName.BinanceFutures:
        return binanceFuturesData;
      case AccountName.BitgetFutures:
        return bitgetFuturesData;
      case AccountName.Bybit:
        return bybitData;
      default:
        return okxData;
    }
  };

  const accountsToRender = isTabletAndMore
    ? chartAccounts
    : chartAccountsInMobile;

  useEffect(() => {
    const sortedData = sortDemoByProfit(accountsToRender, chartsDataMap);
    setSortedChartAccounts(sortedData);
  }, []);

  const bench = okxData.quotes.btc as QuotesItemType[];
  const findStrategyAllDaysData = (item: string) =>
    chooseData(item).quotes.strategy as QuotesItemType[];

  const findStrategyMonthData = (item: string) =>
    findStrategyAllDaysData(item).slice(-32);

  const allDaysOkxData = findStrategyAllDaysData(AccountName.Okx);
  const allDaysBinanceSpotData = findStrategyAllDaysData(
    AccountName.BinanceSpot
  );
  const allDaysBinanceFuturesData = findStrategyAllDaysData(
    AccountName.BinanceFutures
  );
  const allDaysBitgetFuturesData = findStrategyAllDaysData(
    AccountName.BitgetFutures
  );
  const allDaysBybitData = findStrategyAllDaysData(AccountName.Bybit);

  const monthOkxData = findStrategyMonthData(AccountName.Okx);
  const monthBinanceSpotData = findStrategyMonthData(AccountName.BinanceSpot);
  const monthBinanceFuturesData = findStrategyMonthData(
    AccountName.BinanceFutures
  );
  const monthBitgetFuturesData = findStrategyMonthData(
    AccountName.BitgetFutures
  );
  const monthBybitData = findStrategyMonthData(AccountName.Bybit);

  const findMinMaxMain = (isAllDays: boolean) =>
    isAllDays
      ? findRelativeCorrelativeMinMax(
          allDaysOkxData,
          allDaysBinanceSpotData,
          allDaysBinanceFuturesData,
          allDaysBitgetFuturesData,
          allDaysBybitData,
          bench
        ).strategyRange
      : findRelativeCorrelativeMinMax(
          monthOkxData,
          monthBinanceSpotData,
          monthBinanceFuturesData,
          monthBitgetFuturesData,
          monthBybitData,
          bench
        ).strategyRange;

  const findMinMaxBench = (isAllDays: boolean) =>
    isAllDays
      ? findRelativeCorrelativeMinMax(
          allDaysOkxData,
          allDaysBinanceSpotData,
          allDaysBinanceFuturesData,
          allDaysBitgetFuturesData,
          allDaysBybitData,
          bench
        ).benchRange
      : findRelativeCorrelativeMinMax(
          monthOkxData,
          monthBinanceSpotData,
          monthBinanceFuturesData,
          monthBitgetFuturesData,
          monthBybitData,
          bench
        ).benchRange;

  return (
    <div className="relative-block__wrapper">
      <Swiper
        grabCursor={true}
        slidesPerView="auto"
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {sortedChartAccounts.map((item) => (
          <SwiperSlide key={item}>
            <div className="relative-block__slide">
              <RelativeCanvas
                strategyData={findStrategyAllDaysData(item)}
                bench={bench}
                results={chooseData(item).strategy_results}
                isMostPopular={item === MOST_POPULAR_ACCOUNT}
                account={item}
                findMinMaxMain={findMinMaxMain}
                findMinMaxBench={findMinMaxBench}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
