// import BlueGearsIcon from "../../icons/blue/blue-gears-icon";
// import BlueDollarIcon from "../icons/blue-icon/blue-dollar";
// import BlueMoneyIcon from "../icons/blue-icon/blue-money-icon";
// import PlusItem from "./plus-item";
//
// export default function BybitPluses(): JSX.Element {
//   return (
//     <section className="landing__item">
//       <h2 className="landing__title">Benefits</h2>
//
//       <ul className="pluses bybit-pluses__list">
//         <PlusItem
//           // extraClass="bybit-pluses"
//           type="automated"
//           icon={<BlueGearsIcon />}
//           title="Fully automated"
//           extraHeadingClass="right-less"
//           description={
//             <p className="pluses__info">
//               The trading algorithm is based on fundamental scientific research
//               and incorporates all the basic principles of portfolio management
//               and risk control.
//             </p>
//           }
//         />
//
//         <PlusItem
//           // extraClass="bybit-pluses"
//           type="service-fee"
//           icon={<BlueMoneyIcon />}
//           title="Service fee"
//           description={
//             <p className="pluses__info">
//               No upfront payments or credit card are required to start. The
//               first payment is due after 30 days and is 10% of profits only.
//             </p>
//           }
//         />
//
//         <PlusItem
//           // extraClass="bybit-pluses"
//           type="trade"
//           icon={<BlueDollarIcon />}
//           title="No fund transfer"
//           description={
//             <p className="pluses__info">
//               All funds remain on the client's side and under their full
//               control. Connect via a fast API without access to withdrawals.
//             </p>
//           }
//         />
//       </ul>
//     </section>
//   );
// }

import BlueClockIcon from "../../icons/blue/blue-clock-icon";
import BlueDollarIcon from "../../icons/blue/blue-dollar";
import BlueGearsIcon from "../icons/blue-icon/blue-gears-icon";
import PlusItem from "./plus-item";

export default function BybitPluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Why SMARD?</h2>
      <ul className="pluses pluses--binance pluses--tablet-3-colums">
        <PlusItem
          type="automated"
          icon={<BlueGearsIcon />}
          extraClass="top-less"
          title="Fully automated"
          description={
            <p className="pluses__info">
              The trading algorithm is based on fundamental scientific
                research and incorporates all the basic principles
                of portfolio management and risk control.
            </p>
          }
        />

        <PlusItem
          type="prosperity"
          icon={<BlueClockIcon />}
          title="Service fee"
          description={
            <p className="pluses__info">
              No upfront payments or credit card are required to start.
                The first payment is due after 30 days and is 10% of profits only.
            </p>
          }
        />

        <PlusItem
          type="payments"
          icon={<BlueDollarIcon />}
          title="No fund transfer"
          description={
            <p className="pluses__info">
              All funds remain on the client's side and under their full control.
                Connect via a fast API without access to withdrawals.
            </p>
          }
        />
      </ul>
    </section>
  );
}