import FaqItem from "./faq-item";
import { pricingFaqData } from "./faq-data/pricing-faq-data";

function PricingFaqSection() {
  return (
    <section className="landing__item">
      <h2 className="landing__title landing__title--faq">
        Frequently Asked Questions (FAQ)
      </h2>
      <ul className="faq__inner-list">
        {pricingFaqData.map((faq) => {
          return (
            <FaqItem
              key={faq.question}
              question={faq.question}
              answer={<p className="faq-item__paragraph">{faq.answer}</p>}
            />
          );
        })}
      </ul>
    </section>
  );
}

export default PricingFaqSection;
