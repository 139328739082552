import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import NavItemSkeleton from "../../skeletons/nav-item-skeleton";
import { NavAccountType } from "../../../types/general";
import {
  ACCOUNTS_NUMBER,
  AppRoute,
  AuthStatus,
  NavAccountStatus,
  SOON,
} from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { sortOverviewByStatusAndProfit } from "../../../utils/component/overview";
import { useEffect } from "react";
import { loadStrategies } from "../../../store/actions/overview";
import { fetchStrategies } from "../../../store/api-actions/overview";

type AnimatedNavAccountsProps = {
  navAccounts: NavAccountType[];
  isNavAccountsLoading: boolean;
  isNavAccountsOpened: boolean;
};

export default function AnimatedNavAccounts({
  navAccounts,
  isNavAccountsLoading,
  isNavAccountsOpened,
}: AnimatedNavAccountsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { strategies, authStatus, userProfile } = useAppSelector(
    (state) => state
  );
  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const isSoon = (code: string) =>
    code === NavAccountStatus.ComingSoon ? ` ${SOON}` : "";
  const isDisabled = (code: string) =>
    code === NavAccountStatus.ComingSoon ? "nav__sublink--disabled" : "";
  const skeletonRows = Array(ACCOUNTS_NUMBER)
    .fill("")
    .map((_, i) => <NavItemSkeleton key={i} />);

  const sortedAccounts = navAccounts.slice().sort((a, b) => {
    if (a.status.code === b.status.code) {
      return 0;
    }

    if (a.status.code === NavAccountStatus.ComingSoon) {
      return 1;
    }

    return -1;
  });

  const sortNavAccountsByStatusAndProfit = (
    a: NavAccountType,
    b: NavAccountType
  ) => {
    const strategyA = strategies.find((strategy) => strategy.code === a.code);
    const strategyB = strategies.find((strategy) => strategy.code === b.code);

    if (strategyA && strategyB) {
      return sortOverviewByStatusAndProfit(strategyA, strategyB);
    }

    return 0;
  };

  useEffect(() => {
    if (isNoAuth) {
      dispatch(fetchStrategies());
    }

    if (isAuth && userProfile) {
      dispatch(fetchStrategies());
    }

    return () => {
      dispatch(loadStrategies([]));
    };
    // eslint-disable-next-line
  }, [userProfile, isNoAuth, isAuth]);

  return (
    <AnimatePresence initial={false}>
      {isNavAccountsOpened && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          style={{ overflow: "hidden" }}
          transition={{ duration: 0.4 }}
        >
          <ul className="nav__sublist">
            {isNavAccountsLoading
              ? skeletonRows
              : sortedAccounts
                  .sort(sortNavAccountsByStatusAndProfit)
                  .map((item) => (
                    <li key={item.code} className="nav__subitem">
                      <NavLink
                        className={`nav__sublink ${isDisabled(
                          item.status.code
                        )}`}
                        to={`${AppRoute.Accounts}/${item.code}/`}
                      >
                        <div className="nav__sublink-logo-wrapper">
                          <img
                            className={`nav__sublink-logo nav__sublink-logo--${item.code}`}
                            src={item?.menu_logo_url || item.logo_url}
                            alt={`Logo ${item.title}`}
                            width={20}
                            height="auto"
                          />
                        </div>
                        <span className="nav__sublink-text">
                          {`${item.title}${isSoon(item.status.code)}`}
                        </span>
                      </NavLink>
                    </li>
                  ))}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
