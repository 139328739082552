import { useState } from "react";
import { useNavigate } from "react-router-dom";

import DemoResults from "../demo-results/demo-results";
import DemoCanvas from "../canvases/demo-canvas";
import Button from "../button/button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import { setAuthModalOpened } from "../../store/actions";
import { AccountName, AppRoute, AuthStatus } from "../../const";
import { createOkxConnectLink } from "../../utils/id";
import { trackEvent } from "../../utils/tracking";

export default function OkxInsight(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);

  const { authStatus, okxDemo, isOkxConnected } = useAppSelector(
    (state) => state
  );
  const quotes = okxDemo?.quotes as QuotesItemsType;
  const results = okxDemo?.strategy_results as StrategyResultsType;

  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const okxConnectLink = createOkxConnectLink();

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("OKXLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Okx}/`);
  };

  return (
    <section className="landing__item demo-insight">
      <h2 className="landing__title">Trading insights</h2>
      <div className="demo-insight__wrapper">
        {quotes && (
          <DemoCanvas
            type={AccountName.Okx}
            data={quotes}
            isBitcoin={isBitcoinMode}
            handleBitcoinMode={setBitcoinMode}
          />
        )}
        {results && <DemoResults results={results} isBitcoin={isBitcoinMode} />}
      </div>

      {isOkxConnected === null ? (
        <div className="landing__btn-gap"></div>
      ) : isOkxConnected ? (
        <Button
          classElement="demo-insight__btn"
          pattern="white-blue"
          text="View details"
          type="button"
          icon="eye"
          handleClick={handleViewClick}
        />
      ) : (
        <Button
          classElement="demo-insight__btn"
          pattern="white-blue"
          text="Start now"
          icon="arrow-right"
          type={isNoAuth ? "button" : "link"}
          linkAddress={okxConnectLink}
          isSelfLink
          handleClick={handleConnectClick}
        />
      )}
    </section>
  );
}
