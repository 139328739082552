import { StrategyType } from "../../../types/strategy";
import { formatStrategy } from "../../../utils/amount";
import {
  addBoardSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type OverviewLastMonthProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyType;
};

export default function OverviewLastMonth({
  strategyStatus,
  isTabletAndMore,
  overview,
}: OverviewLastMonthProps): JSX.Element {
  const currency = overview.currency;
  const amount = overview.profit_last_30_days;
  const percent = overview.profit_last_30_days_percent;

  const avg_monthly_return = overview.avg_monthly_return;

  return amount !== null && percent !== null ? (
    <div className="overview-board__total overview-board__total--last-month">
      <p className="overview-board__amount">
        {addBoardSign(
          strategyStatus,
          formatStrategy(avg_monthly_return).amount,
          formatStrategy(avg_monthly_return).amount
        )}
        <span className="overview-board__number">
          {displayStrategyAmount(
            "last-month",
            strategyStatus,
            isTabletAndMore,
            avg_monthly_return,
            currency,
            avg_monthly_return
          )}
        </span>
      </p>
      <span className="overview-board__text">Average monthly</span>
    </div>
  ) : (
    <></>
  );
}
