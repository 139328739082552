import { useState } from "react";

import TogglesBase from "../toggles/toggles-base";
import BinanceSpotIcon from "../../icons/account/binance-spot-icon";
import PlusIcon from "../../icons/sign/plus-icon";
import BinanceFuturesIcon from "../../icons/account/binance-futures-icon";
import BinanceChart from "../charts/binance-chart";
import { QuotesItemType, QuotesItemsType } from "../../types/details";

type DeversifyCanvasProps = {
  type: string;
  isBitcoinMode: boolean;
  setBitcoinMode: (isBitcoinMode: boolean) => void;
  data: QuotesItemsType;
};

export default function DeversifyCanvas({
  type,
  isBitcoinMode,
  setBitcoinMode,
  data,
}: DeversifyCanvasProps): JSX.Element {
  const [isAllDays, setAllDays] = useState<boolean>(true);

  const btcData = data.btc as QuotesItemType[];
  const ethData = data.eth as QuotesItemType[];
  const strategyData = data.strategy as QuotesItemType[];

  return (
    <div className="binance-canvas">
      <div className="binance-canvas__logo">
        <span className="binance-canvas__icon binance-canvas__icon--binance-spot">
          <BinanceSpotIcon />
        </span>
        <span className="binance-canvas__icon binance-canvas__icon--plus">
          <PlusIcon />
        </span>
        <span className="binance-canvas__icon binance-canvas__icon--binance-futures">
          <BinanceFuturesIcon />
        </span>
      </div>
      <BinanceChart
        main={strategyData}
        btcBench={btcData}
        ethBench={ethData}
        isAllDays={isAllDays}
        isBitcoin={isBitcoinMode}
        type={type}
      />
      <TogglesBase
        type={type}
        isBitcoinMode={isBitcoinMode}
        isAllDays={isAllDays}
        setBitcoinMode={setBitcoinMode}
        setAllDays={setAllDays}
      />
    </div>
  );
}
