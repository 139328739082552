import OverviewTrending from "./overview-trending";
import OverviewChart from "../../charts/overview-chart";
import { StrategyType } from "../../../types/strategy";
import { formatStrategyTrending } from "../../../utils/amount";

type OverviewChartTrendingProps = {
  overview: StrategyType;
};

export default function OverviewDynamics({
  overview,
}: OverviewChartTrendingProps): JSX.Element {
  const amounts = overview.amounts;
  const profitPercent = overview.profit_last_30_days_percent_chart;
  return (
    <div className="overview-board__dynamics">
      {amounts && profitPercent !== null && (
        <OverviewChart
          result={formatStrategyTrending(profitPercent).amount}
          amounts={amounts}
        />
      )}
      <OverviewTrending overview={overview} />
    </div>
  );
}
