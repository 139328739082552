export default function BluePercentDownIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#60A5FA"
          d="M14 0C6.33 0 0 6.33 0 14s6.33 14 14 14 14-6.33 14-14S21.67 0 14 0Z"
        />
        <path
          fill="#2563EB"
          d="M28 14c0 7.67-6.33 14-14 14V0c7.67 0 14 6.33 14 14Z"
        />
        <path
          fill="#60A5FA"
          d="M9.7 7c.94 0 1.65.37 2.14 1.12.48.74.73 1.78.73 3.14a6 6 0 0 1-.7 3.16 2.37 2.37 0 0 1-2.17 1.13c-.9 0-1.6-.37-2.08-1.13a5.94 5.94 0 0 1-.71-3.16c0-1.36.23-2.4.68-3.14A2.3 2.3 0 0 1 9.7 7Zm0 1.1c-.5 0-.88.27-1.12.8-.24.52-.36 1.3-.36 2.36 0 1.05.12 1.84.36 2.37.24.54.61.8 1.12.8.53 0 .92-.26 1.18-.8.25-.53.38-1.32.38-2.37s-.13-1.83-.38-2.36c-.25-.53-.65-.8-1.18-.8Zm8.7-.9L10.79 20.8H9.41L17.03 7.2h1.38Zm-.36 5.26c.93 0 1.65.37 2.13 1.12.5.74.74 1.78.74 3.14 0 1.34-.24 2.4-.7 3.15A2.37 2.37 0 0 1 18.03 21c-.9 0-1.6-.38-2.08-1.13a5.92 5.92 0 0 1-.71-3.15c0-1.36.22-2.4.68-3.14a2.3 2.3 0 0 1 2.11-1.12Zm0 1.12c-.5 0-.88.26-1.12.78-.24.52-.36 1.3-.36 2.36 0 1.04.12 1.84.36 2.37s.61.8 1.12.8c.53 0 .92-.27 1.18-.79.25-.53.38-1.32.38-2.38 0-1.05-.13-1.83-.38-2.35-.25-.53-.65-.8-1.18-.8Z"
        />
        <mask
          id="b"
          width="15"
          height="14"
          x="6"
          y="7"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "alpha" }}
        >
          <path
            fill="#fff"
            d="M9.7 7c.94 0 1.65.37 2.14 1.12.48.74.73 1.78.73 3.14a6 6 0 0 1-.7 3.16 2.37 2.37 0 0 1-2.17 1.13c-.9 0-1.6-.37-2.08-1.13a5.94 5.94 0 0 1-.71-3.16c0-1.36.23-2.4.68-3.14A2.3 2.3 0 0 1 9.7 7Zm0 1.1c-.5 0-.88.27-1.12.8-.24.52-.36 1.3-.36 2.36 0 1.05.12 1.84.36 2.37.24.54.61.8 1.12.8.53 0 .92-.26 1.18-.8.25-.53.38-1.32.38-2.37s-.13-1.83-.38-2.36c-.25-.53-.65-.8-1.18-.8Zm8.7-.9L10.79 20.8H9.41L17.03 7.2h1.38Zm-.36 5.26c.93 0 1.65.37 2.13 1.12.5.74.74 1.78.74 3.14 0 1.34-.24 2.4-.7 3.15A2.37 2.37 0 0 1 18.03 21c-.9 0-1.6-.38-2.08-1.13a5.92 5.92 0 0 1-.71-3.15c0-1.36.22-2.4.68-3.14a2.3 2.3 0 0 1 2.11-1.12Zm0 1.12c-.5 0-.88.26-1.12.78-.24.52-.36 1.3-.36 2.36 0 1.04.12 1.84.36 2.37s.61.8 1.12.8c.53 0 .92-.27 1.18-.79.25-.53.38-1.32.38-2.38 0-1.05-.13-1.83-.38-2.35-.25-.53-.65-.8-1.18-.8Z"
          />
        </mask>
        <g mask="url(#b)">
          <path fill="#A0CBFF" d="M5.51 4.2h8.4v18.2h-8.4z" />
        </g>
        <path
          fill="#60A5FA"
          d="M21.25 18h4.5v5.71H28L23.5 28 19 23.71h2.25V18Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
