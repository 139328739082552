import Message from "../../message/message";
import BybitMinNotionalError from "../notes/bybit/bybit-min-notional-error";
import BybitChangeModeNote from "../notes/bybit/bybit-change-mode-note";
import BybitChangeModeAgainNote from "../notes/bybit/bybit-change-mode-again-note";
import AmountRangeNote from "../notes/amount-rage-note";
import { minNotional } from "../../../utils/env";
import { ResponseStatusType } from "../../../types/details";
import {
  ErrorType,
  BYBIT_CHANGE_MODE_ERROR,
  BYBIT_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE,
  BYBIT_ACCOUNT_NOT_UNIFIED_ERROR,
  BYBIT_KEYS_READ_ONLY,
} from "../../../const";
import BybitChangeUtaNote from "../notes/bybit/bybit-change-uta-note";
import BybitChangeKeysPermissions from "../notes/bybit/bybit-change-keys-permissions";

type BybitStep3MessageConnectedProps = {
  availableAmount: number;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
  changePositionModeStatus: null | ResponseStatusType;
  sendAmountToStartStatus: null | ResponseStatusType;
};

export default function BybitStep3MessageConnected({
  sendAmountToStartStatus,
  changePositionModeStatus,
  loadAmountToStartStatus,
  checkPositionModeStatus,
  availableAmount,
}: BybitStep3MessageConnectedProps): JSX.Element {
  const isCodeEqualToUnexpectedMode = (code: number) =>
    code === BYBIT_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE;
  const isCodeEqualToChangeModeError = (code: number) =>
    code === BYBIT_CHANGE_MODE_ERROR;

  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isStartStatusFail = sendAmountToStartStatus?.isSuccess === false;
  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const startStatus = sendAmountToStartStatus;
  const checkModeStatus = checkPositionModeStatus;
  const loadAmountToStartCode = loadAmountToStartStatus?.code;

  const isAccountNotUtaError = (code: number) =>
    BYBIT_ACCOUNT_NOT_UNIFIED_ERROR === code;

  const isKeysReadOnly = (code: number | undefined) =>
    BYBIT_KEYS_READ_ONLY === code;

  const shouldChangeModeNote =
    (startStatus &&
      isStartStatusFail &&
      startStatus.code &&
      isCodeEqualToUnexpectedMode(startStatus.code)) ||
    (checkModeStatus &&
      checkPositionModeStatus &&
      checkModeStatus.code &&
      isCodeEqualToUnexpectedMode(checkModeStatus.code));

  const shouldChangeModeAgainNote =
    changePositionModeStatus &&
    isChangeModeFail &&
    changePositionModeStatus.code &&
    isCodeEqualToChangeModeError(changePositionModeStatus.code);

  return loadAmountToStartCode &&
    isAccountNotUtaError(loadAmountToStartCode) ? (
    <Message status="error" message={<BybitChangeUtaNote />} />
  ) : shouldChangeModeAgainNote ? (
    <Message status="error" message={<BybitChangeModeAgainNote />} />
  ) : shouldChangeModeNote ? (
    <Message status="error" message={<BybitChangeModeNote />} />
  ) : isStartStatusFail ? (
    <Message status="error" message={sendAmountToStartStatus.message} />
  ) : isKeysReadOnly(loadAmountToStartCode) && loadAmountToStartCode ? (
    <Message status="error" message={<BybitChangeKeysPermissions />} />
  ) : isLoadAmountFail || isCheckModeFail || isChangeModeFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<BybitMinNotionalError />} />
  );
}
