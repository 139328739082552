import { useLocation } from "react-router-dom";
import { AppRoute, EMPTY_DATA } from "../../const";
import { ComissionsType, StrategyResultsType } from "../../types/details";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

type DemoResultsProps = {
  results: StrategyResultsType;
  isBitcoin: boolean;
};

export default function DemoResults({
  results,
  isBitcoin,
}: DemoResultsProps): JSX.Element {
  const { pathname } = useLocation();

  const commissions = results?.commissions as ComissionsType;
  const isOkxLanding = pathname === AppRoute.Okx;
  const isBitgetLanding = pathname === AppRoute.Bitget;
  const isBybitLanding = pathname === AppRoute.Bybit;

  return (
    <section className="trading-results block">
      <h2 className="block__heading">Historical results</h2>

      <ul className="trading-results__table">
        <li className="trading-results__row">
          <p className="trading-results__name">Sharpe ratio</p>
          <p className="trading-results__value">
            {displayResultsAmount(results.sharpe_ratio)}
          </p>
        </li>
        <li className="trading-results__row">
          <p className="trading-results__name">Annualized return</p>
          <p className="trading-results__value">
            {displayResultsPercent(results.annualized_return)}
          </p>
        </li>
        <li className="trading-results__row">
          <p className="trading-results__name">Average monthly return</p>
          <p className="trading-results__value">
            {displayResultsPercent(results.avg_monthly_return)}
          </p>
        </li>

        <li className="trading-results__row">
          <p className="trading-results__name">Return</p>
          <p className="trading-results__value">
            {displayResultsPercent(results.total_return_percent)}
          </p>
        </li>
        {isOkxLanding || isBitgetLanding || isBybitLanding || (
          <li className="trading-results__row">
            <p className="trading-results__name">Max drawdown</p>
            <p className="trading-results__value">
              {displayResultsPercent(results.mdd)}
            </p>
          </li>
        )}

        <li className="trading-results__row">
          <p className="trading-results__name">
            {`Return vs ${isBitcoin ? "BTC" : "ETH"}`}
          </p>
          <p className="trading-results__value">
            {isBitcoin
              ? displayResultsPercent(results.return_vs_btc)
              : displayResultsPercent(results.return_vs_eth)}
          </p>
        </li>
        {isOkxLanding || isBitgetLanding || isBybitLanding ? null : (
          <li className="trading-results__row">
            <p className="trading-results__name">Exchange commissions paid</p>
            <p className="trading-results__value">
              {commissions
                ? displayResultsPercent(commissions.relative)
                : EMPTY_DATA}
            </p>
          </li>
        )}
      </ul>
    </section>
  );
}
