import SkeletonChartIcon from "../icons/skeleton-chart-icon";

export default function CoinsSkeleton(): JSX.Element {
  return (
    <div className="sk-coins animate-pulse">
      <div className="sk-coins__row sk-coins__row--heading">
        <div className="sk-coins__heading"></div>
        <div className="sk-coins__heading"></div>
        <div className="sk-coins__heading"></div>
        <div className="sk-coins__heading"></div>
        <div className="sk-coins__heading"></div>
      </div>
      {Array(30)
        .fill("")
        .map((_, i) => (
          <div className="sk-coins__row" key={i}>
            <div className="sk-coins__instrument-wrapper">
              <div className="sk-coins__logo"></div>
              <div className="sk-coins__instrument"></div>
            </div>
            <div className="sk-coins__amount sk-coins__amount--price"></div>
            <div className="sk-coins__amount sk-coins__amount--change"></div>
            <div className="sk-coins__amount sk-coins__amount--market-cap"></div>
            <div className="sk-coins__amount sk-coins__amount--volume"></div>
            <div className="sk-coins__chart">
              <SkeletonChartIcon />
            </div>
          </div>
        ))}
    </div>
  );
}
