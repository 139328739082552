import { useState } from "react";

import DemoResults from "../demo-results/demo-results";
import DemoCanvas from "../canvases/demo-canvas";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import { AccountName } from "../../const";

export default function BybitInsight(): JSX.Element {
  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);

  const { bybitDemo } = useAppSelector((state) => state);
  const quotes = bybitDemo?.quotes as QuotesItemsType;
  const results = bybitDemo?.strategy_results as StrategyResultsType;

  return (
    <section className="landing__item demo-insight">
      <h2 className="landing__title">Trading insights</h2>
      <div className="demo-insight__wrapper">
        {quotes && (
          <DemoCanvas
            type={AccountName.Bybit}
            data={quotes}
            isBitcoin={isBitcoinMode}
            handleBitcoinMode={setBitcoinMode}
          />
        )}
        {results && <DemoResults results={results} isBitcoin={isBitcoinMode} />}
      </div>
    </section>
  );
}