import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../../button/button";
import ConnectPoint3 from "../../points/connect-point3";
import OkxStep3Message from "../../messages/okx-step3-message";
import OkxStep3Fields from "../../fields/okx-step3-fields";
import { useAppDispatch } from "../../../../hooks";
import { formatedMinNotional } from "../../../../utils/env";
import { sendAmountToStartOkx } from "../../../../store/api-actions/start";
import { ResponseStatusType } from "../../../../types/details";
import {
  setChangePositionModeStatus,
  setCheckPositionModeStatus,
  setLoadAmountToStartStatus,
  setSendAmountToStartStatus,
} from "../../../../store/actions/start";
import {
  ConnectSteps,
  OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE,
} from "../../../../const";

type OkxConnectStep3Props = {
  pointId: string;
  activeStep: string;
  availableAmount: number;
  isLoading: boolean;
  setActiveStep: Dispatch<SetStateAction<string>>;
  sendAmountToStartStatus: null | ResponseStatusType;
  changePositionModeStatus: null | ResponseStatusType;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
};

export default function OkxConnectStep3({
  pointId,
  activeStep,
  availableAmount,
  isLoading,
  setActiveStep,
  sendAmountToStartStatus,
  changePositionModeStatus,
  loadAmountToStartStatus,
  checkPositionModeStatus,
}: OkxConnectStep3Props): JSX.Element {
  const dispatch = useAppDispatch();
  const startBtnRef = useRef<HTMLButtonElement | null>(null);
  const [isShowMessage, setShowMessage] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setFocus,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isChangeModeSuccess = changePositionModeStatus?.isSuccess;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isSendAmountFail = sendAmountToStartStatus?.isSuccess === false;
  const isLoadAmountSuccess = loadAmountToStartStatus?.isSuccess;
  const isSendAmountFailWithNote =
    sendAmountToStartStatus?.code ===
    OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE;
  const isStepActive = activeStep === ConnectSteps.STEP3;
  const blockClass = isStepActive ? "block--active" : "block--disabled";
  const backDisabledClass = isLoading ? "btn--disabled" : "";
  const submitDisabledClass =
    (!isValid && isStepActive) ||
    isLoading ||
    isCheckModeFail ||
    isChangeModeFail ||
    isLoadAmountFail ||
    isSendAmountFailWithNote
      ? "btn--disabled"
      : "";

  // onSubmit
  const onSubmit = () => {
    const data = getValues(["amount"]);
    const [amount] = data;
    dispatch(
      sendAmountToStartOkx({
        amount,
        currency: "usdt",
      })
    );
  };

  const handleBackClick = () => {
    setActiveStep(ConnectSteps.STEP2);
    setValue("amount", "");
    clearStatuses();
    setShowMessage(false);
  };

  const clearStatuses = () => {
    if (loadAmountToStartStatus) {
      dispatch(setLoadAmountToStartStatus(null));
    }

    if (checkPositionModeStatus) {
      dispatch(setCheckPositionModeStatus(null));
    }

    if (changePositionModeStatus) {
      dispatch(setChangePositionModeStatus(null));
    }

    if (sendAmountToStartStatus) {
      dispatch(setSendAmountToStartStatus(null));
    }
  };

  // Действия при переходе на 3й блок
  useEffect(() => {
    if (isStepActive) {
      setFocus("amount");
      setValue("amount", availableAmount);
      trigger("amount");
    }
  }, [availableAmount, isStepActive, setFocus, setValue, trigger]);

  // Обнуление статуса запросов при успехе на change-mode,
  // чтобы корректно запросить новый на start и убрать сообщение у check-mode и сhange-mode
  useEffect(() => {
    if (changePositionModeStatus) {
      dispatch(setCheckPositionModeStatus(null));
    }

    if (isChangeModeSuccess) {
      dispatch(setChangePositionModeStatus(null));
    }

    if (isChangeModeSuccess && sendAmountToStartStatus) {
      // случай когда при запросе start пришел код 49999, далее успешное change-mode
      dispatch(setSendAmountToStartStatus(null));
    }
  }, [
    changePositionModeStatus,
    checkPositionModeStatus,
    dispatch,
    isChangeModeSuccess,
    sendAmountToStartStatus,
  ]);

  // Для отображения первого сообщения
  useEffect(() => {
    if (isLoadAmountFail || (isLoadAmountSuccess && checkPositionModeStatus)) {
      setShowMessage(true);
    }
  }, [checkPositionModeStatus, isLoadAmountFail, isLoadAmountSuccess]);

  // Убирать фокус с кнопки Start, если ошибка запуска
  useEffect(() => {
    if (isSendAmountFail && startBtnRef.current) {
      startBtnRef.current.blur();
    }
  }, [isSendAmountFail]);

  return (
    <form
      className={`details__item connect-step connect-step--final block block--step ${blockClass}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 className="block__heading">
        Specify the amount to&nbsp;be&nbsp;traded&nbsp;by&nbsp;SMARD
      </h3>
      <p className="connect-step__intro">
        {`We will only use the amount you specify for trading. Minimum ${formatedMinNotional} USDT.`}
      </p>
      <div className="connect-step__fields-wrapper">
        <OkxStep3Fields
          availableAmount={availableAmount}
          isLoading={isLoading}
          setValue={setValue}
          trigger={trigger}
          register={register}
        />
        <div className="connect-step__message">
          {isShowMessage && (
            <OkxStep3Message
              availableAmount={availableAmount}
              sendAmountToStartStatus={sendAmountToStartStatus}
              changePositionModeStatus={changePositionModeStatus}
              checkPositionModeStatus={checkPositionModeStatus}
              loadAmountToStartStatus={loadAmountToStartStatus}
            />
          )}
        </div>
      </div>

      <div className="connect-step__btn-wrapper">
        <Button
          classElement={backDisabledClass}
          pattern="blue-white"
          text="Back"
          icon="arrow-left"
          type="button"
          handleClick={handleBackClick}
        />
        <Button
          classElement={submitDisabledClass}
          pattern="white-blue"
          text="Start"
          icon={isLoading ? "arrow-loader" : "arrow-right"}
          type="submit"
          ref={startBtnRef}
        />
      </div>
      <ConnectPoint3 pointId={pointId} isPointActive={isStepActive} />
    </form>
  );
}
