import Button from "../button/button";
import LogoIcon from "../icons/logo-icon";
import BybitIcon from "../../icons/exchange/bybit-icon";
import PlusIcon from "../icons/sign/plus-icon";
import { trackEvent } from "../../utils/tracking";
import {AccountName, AppRoute, AuthStatus, BYBIT_REGISTER_LINK, OKX_REGISTER_LINK} from "../../const";
import {createBybitConnectLink} from "../../utils/id";
import {useState} from "react";
import {checkIfDesktop} from "../../utils/breakpoint";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {setAuthModalOpened} from "../../store/actions";

export default function BybitPromo(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus, isBybitConnected } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const bybitConnectLink = createBybitConnectLink();

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Bybit}/`);
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("BybitLandingFastConnect", "Clicked");
  };

  const handleRegisterClick = () => {
    trackEvent("BybitLandingRegisterAccount", "Clicked");
  };

  return (
    <section className="landing__item bybit-promo">
      <div className="bybit-promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">AUTOMATE YOUR PORTFOLIO MANAGEMENT</span> on{" "}
          <span className="landing__heading-large">Bybit</span>
        </h1>
      </div>

      <div className="bybit-promo__wrapper">
        <p className="bybit-promo__intro">
          Integrated with Bybit, SMARD enables
          seamless trading on a highly liquid exchange. Advanced algorithms,
          automated portfolio management and risk controls,
          allowing SMARD to benefit from market
          opportunities without any unnecessary risk.
          <br/>
          <br/>
          Still don't have a Bybit account?
          Create one now and get a 10% discount on exchange commission fees until
          September 19, 2024.
        </p>
      </div>

      <div className="bybit-promo__icons">
        <span className="bybit-promo__icon bybit-promo__icon--smard">
          <LogoIcon />
        </span>
        <span className="bybit-promo__icon bybit-promo__icon--plus">
          <PlusIcon />
        </span>
        <span className="bybit-promo__icon bybit-promo__icon--bybit">
          <BybitIcon />
        </span>
      </div>

      <div className="bybit-promo__btns">
        {isBybitConnected === null ? (
          <div className="landing__btn-gap"></div>
        ) : isBybitConnected ? (
          <Button
            pattern="white-blue"
            text="View trading results"
            type="button"
            icon="eye"
            size="super"
            handleClick={handleViewClick}
          />
        ) : (
          <>
            <Button
              pattern="white-blue"
              text="Register a Bybit account"
              icon="key-in-circle"
              type="link"
              size="super-big"
              linkAddress={BYBIT_REGISTER_LINK}
              handleClick={handleRegisterClick}
            />
            <Button
              pattern="blue-white"
              text="Connect an existing account"
              icon="check"
              type={isNoAuth ? "button" : "link"}
              size="huge"
              linkAddress={bybitConnectLink}
              isSelfLink
              handleClick={handleConnectClick}
            />
          </>
        )}
      </div>
    </section>
  );
}