export const addNullIfNeeded = (amount: number, final: string) => {
  if (amount < 10 && findAmountDecimals(amount) === 1) {
    return `${final}0`;
  }
  return final;
};

export const localAmount = (amount: number) => {
  return amount.toLocaleString(undefined, { maximumFractionDigits: 8 });
};

export const localInvoiceAmount = (amount: number) => {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumberWithSpaces = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    useGrouping: true,
    minimumFractionDigits: 0,
  })
    .format(amount)
    .replace(/,/g, " ");
};

export const addPlusIfPositive = (amount: number) => {
  return amount > 0 ? "+" : "";
};

export const deleteMinusIfNegative = (amount: number) => {
  const string = amount.toString();
  if (string.charAt(0) === "-") {
    return Number(string.slice(1));
  }
  return amount;
};

export const deleteDigitsAfter2Decimals = (amount: number) => {
  if (Number.isInteger(amount)) {
    return amount;
  }

  if (amount?.toString().split(".").pop()?.length === 1) {
    return amount?.toFixed(1);
  }

  return amount?.toFixed(2);
};

// Находит кол-во цифр после точки
export const findAmountDecimals = (amount: number) =>
  Number.isInteger(amount)
    ? 0
    : (amount.toString().split(".").pop()?.length as number);

export const makePositiveAmount = (amount: number) => Math.abs(amount);
const makePositiveInterger = (amount: number) => Math.abs(Math.round(amount));
const checkIsNegative = (initialAmount: number, formattedAmount: number) =>
  initialAmount < 0 ? -Math.abs(formattedAmount) : formattedAmount;

const shareAmountWithPoint = (amount: number, place: number) =>
  makePositiveInterger(amount).toString().slice(0, place) +
  "." +
  makePositiveInterger(amount).toString().slice(place);

export const formatMoreAndEqual100 = (amount: number) => {
  if (makePositiveInterger(amount) >= 95000000000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -9)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "B",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 9500000000) {
    const amountShared = Number(shareAmountWithPoint(amount, -9));
    const amountformatted = Number(amountShared.toFixed(1));

    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "B",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 95000000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -6)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "M",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 9500000) {
    const amountShared = Number(shareAmountWithPoint(amount, -6));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "M",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 95000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -3)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "K",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 9500) {
    const amountShared = Number(shareAmountWithPoint(amount, -3));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "K",
      isQuantityTooltip: false,
      isAmountTooltip: true,
    };
  }

  if (makePositiveInterger(amount) >= 1000) {
    return {
      amount: Number(amount.toFixed(0)),
      short: "",
      isQuantityTooltip: false,
      isAmountTooltip: Boolean(findAmountDecimals(amount)),
    };
  }

  return {
    amount: Number(amount.toFixed(1)),
    short: "",
    isQuantityTooltip: false,
    isAmountTooltip: findAmountDecimals(amount) > 1,
  };
};

export const formatAmount = (amount: number) => {
  if (makePositiveInterger(amount) >= 100) {
    return formatMoreAndEqual100(amount);
  }

  if (amount === 0) {
    return {
      amount: 0,
      short: "",
      isAmountTooltip: false,
    };
  }

  if (makePositiveAmount(amount) <= 0.001) {
    return {
      amount: null,
      short: "tiny",
      isAmountTooltip: true,
    };
  }

  if (findAmountDecimals(amount) > 2) {
    return {
      amount: Number(amount.toFixed(2)),
      short: "",
      isAmountTooltip: true,
    };
  }

  return {
    //  Округление до 1 числа если не целое, ноль убирается, тк Number()
    amount: Number(amount.toFixed(2)),
    short: "",
    isAmountTooltip: false,
  };
};

export const formatQuantity = (amount: number) => {
  // the same as localAndShortAmount, difference is in tooltip
  if (makePositiveInterger(amount) >= 100) {
    return formatMoreAndEqual100(amount);
  }

  if (amount === 0) {
    return {
      amount: 0,
      short: "",
      isQuantityTooltip: false,
    };
  }

  if (makePositiveAmount(amount) <= 0.001) {
    return {
      amount: null,
      short: "tiny",
      isQuantityTooltip: true,
    };
  }

  if (findAmountDecimals(amount) > 2) {
    return {
      amount: Number(amount.toFixed(2)),
      short: "",
      isQuantityTooltip: false,
    };
  }

  return {
    amount: Number(amount.toFixed(2)),
    short: "",
    isQuantityTooltip: false,
  };
};

export const formatTrending = (amount: number) => {
  // the same as localAndShortAmount, difference is in tooltip and checking less 100
  if (makePositiveInterger(amount) >= 100) {
    return formatMoreAndEqual100(amount);
  }

  if (amount === 0 || makePositiveAmount(amount) <= 0.01) {
    return {
      amount: 0,
      short: "",
    };
  }

  // Не сможем вернуть число с пустыми нулями после одного decimal если поставим Number
  // И также локализация убирает ненужный ноль всегда
  // if (findAmountDecimals(amount) === 1) {
  //   return {
  //     amount: Number(`${amount.toFixed(2)}0`),
  //     short: "",
  //   };
  // }

  if (findAmountDecimals(amount) > 2) {
    return {
      amount: Number(amount.toFixed(2)),
      short: "",
    };
  }

  return {
    amount: amount,
    short: "",
  };
};

export const formatStrategyTrending = (amount: number) => {
  if (makePositiveInterger(amount) >= 950000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -6)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "M",
    };
  }

  if (makePositiveInterger(amount) >= 950) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -3)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "K",
    };
  }

  if (makePositiveInterger(amount) >= 10) {
    return {
      amount: Number(amount.toFixed(0)),
      short: "",
    };
  }

  if (amount === 0 || makePositiveAmount(amount) < 0.1) {
    return {
      amount: 0,
      short: "",
    };
  }

  return {
    amount: Number(amount.toFixed(1)),
    short: "",
  };
};

export const formatChartAmounts = (amount: number) => {
  if (makePositiveInterger(amount) >= 9500000) {
    const amountShared = Number(shareAmountWithPoint(amount, -6));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "M",
    };
  }

  if (makePositiveInterger(amount) >= 9500) {
    const amountShared = Number(shareAmountWithPoint(amount, -3));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "K",
    };
  }

  return {
    amount: Math.round(amount / 10) * 10,
    short: "",
  };
};

export const formatTradingResults = (amount: number) => {
  if (makePositiveInterger(amount) >= 9500000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -6)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "M",
    };
  }

  if (makePositiveInterger(amount) >= 9500) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -3)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "K",
    };
  }

  if (makePositiveInterger(amount) >= 1000) {
    return {
      amount: Number(amount.toFixed(0)),
      short: "",
    };
  }

  if (makePositiveInterger(amount) >= 100) {
    return {
      amount: Number(amount.toFixed(1)),
      short: "",
    };
  }

  if (amount === 0) {
    return {
      amount: 0,
      short: "",
    };
  }

  return {
    amount: Number(amount.toFixed(2)),
    short: "",
  };
};

export const formatStrategy = (amount: number) => {
  if (makePositiveInterger(amount) >= 95000000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -6)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "M",
    };
  }

  if (makePositiveInterger(amount) >= 9500000) {
    const amountShared = Number(shareAmountWithPoint(amount, -6));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "M",
    };
  }

  if (makePositiveInterger(amount) >= 95000) {
    const amountRounded = Math.round(Number(shareAmountWithPoint(amount, -3)));
    return {
      amount: checkIsNegative(amount, amountRounded),
      short: "K",
    };
  }

  if (makePositiveInterger(amount) >= 9500) {
    const amountShared = Number(shareAmountWithPoint(amount, -3));
    const amountformatted = Number(amountShared.toFixed(1));
    return {
      amount: checkIsNegative(amount, amountformatted),
      short: "K",
    };
  }

  if (makePositiveInterger(amount) >= 100) {
    return {
      amount: Number(amount.toFixed(0)),
      short: "",
    };
  }

  if (amount === 0 || makePositiveAmount(amount) < 0.1) {
    return {
      amount: 0,
      short: "",
    };
  }

  if (makePositiveAmount(amount) > 0 && makePositiveAmount(amount) < 1) {
    return {
      amount: Number(amount.toFixed(1)),
      short: "",
    };
  }

  return {
    amount: Math.round(amount),
    short: "",
  };
};

export const formatStrategyWithDeciminals = (amount: number) => {
  if (makePositiveAmount(amount) > 0 && makePositiveAmount(amount) < 100) {
    return {
      amount: Number(amount.toFixed(2)),
      short: "",
    };
  }

  if (
    makePositiveInterger(amount) >= 100 &&
    makePositiveAmount(amount) < 1000
  ) {
    return {
      amount: Number(amount.toFixed(1)),
      short: "",
    };
  }
  return formatStrategy(amount);
};
