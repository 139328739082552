export default function PersonWithDollarIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.93 8H14v.76c-.36.04-.68.12-.97.24-.32.14-.58.34-.76.59-.19.25-.28.54-.28.88 0 .32.07.61.23.85.15.24.38.45.7.62.28.16.65.3 1.08.45v1.5a5.36 5.36 0 0 1-1.8-.43l-.2-.08v1.27l.08.03c.26.11.56.2.9.26H13c.31.06.65.1 1.01.1V16h.92v-1a2.79 2.79 0 0 0 1.5-.54c.38-.32.57-.73.57-1.22 0-.37-.1-.68-.3-.92-.2-.23-.46-.42-.78-.57-.3-.14-.63-.26-1-.38V9.92a5.97 5.97 0 0 1 1.36.32l.12.05.46-1.1-.12-.05a5.06 5.06 0 0 0-1.81-.4V8Zm-1.2 2.02c.08-.03.17-.06.28-.08v1.11a2.3 2.3 0 0 1-.3-.13.6.6 0 0 1-.23-.2.53.53 0 0 1-.07-.28c0-.1.03-.18.08-.25a.55.55 0 0 1 .24-.17Zm1.2 3.82V12.7l.33.14c.12.07.2.13.25.2.05.07.08.15.08.26 0 .15-.06.27-.19.36-.1.08-.25.15-.47.18ZM11.5 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM10 4a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.4 8.6C10.6 8.2 9.7 8 8.75 8H6.26A6.26 6.26 0 0 0 0 14.25a.75.75 0 0 0 1.5 0A4.76 4.76 0 0 1 6.26 9.5h2.48c.72 0 1.4.16 2 .44.08-.4.23-.78.5-1.13.05-.08.1-.15.17-.22Z"
      />
    </svg>
  );
}
