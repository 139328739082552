import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Hint from "../hint/hint";
import TableAbsolute from "../table-items/table-result";
import TableDate from "../table-items/table-date";
import TableInstrument from "../table-items/table-instrument";
import TableToggle from "../table-items/table-toggle";
import AnimatedTableChart from "../table-items/animated-table-chart";
import TableTrending from "../table-items/table-trending";
import { useAppSelector } from "../../hooks";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { RecentActivityType } from "../../types/details";

export default function RecentActivity(): JSX.Element {
  const { authStatus, strategyDetails } = useAppSelector((state) => state);
  const activityList = strategyDetails?.recent_activity as RecentActivityType[];
  const currency = strategyDetails?.overview.currency as string;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const [openedChart, setOpenedChart] = useState<null | number>(null);

  const handleChartToggle = (index: number) => {
    if (index === openedChart) {
      setOpenedChart(null);
      return;
    }
    setOpenedChart(index);
  };

  const titleRow = (
    <li className="recent-activity__title-row">
      <div className="recent-activity__row-wrapper">
        <p className="recent-activity__title recent-activity__title--date">
          Date
        </p>
        <p className="recent-activity__title recent-activity__title--instrument">
          Instrument
        </p>
        <p className="recent-activity__title recent-activity__title--result">
          <span className="recent-activity__title-title">Result</span>
          <span className="recent-activity__title-gap">{currency}</span>
        </p>
      </div>
    </li>
  );

  const hint = (
    <Hint
      type="block__hint"
      id="details/recent-activity"
      hintText={HINTS_TEXT["details/recent-activity"]}
      block="recent-activity"
    />
  );

  return (
    <section className="details__item details__activity recent-activity block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Recent activity</h2>
        {isNoAuth && hint}
      </div>
      {activityList && activityList.length ? (
        <ul className="recent-activity__table">
          {titleRow}
          {/* ROWS */}
          {activityList.slice(0, 10).map((item, index) => {
            return (
              <li
                className="recent-activity__row"
                key={index}
                onClick={() => {
                  handleChartToggle(index);
                }}
              >
                <div className="recent-activity__row-wrapper">
                  <TableDate date={item.date} />
                  <TableInstrument
                    url={item.logo_url}
                    title={item.title}
                    ticker={item.ticker}
                  />

                  <div className="recent-activity__result">
                    <div className="recent-activity__result-absolute">
                      <TableAbsolute amount={item.result.absolute} />
                    </div>
                    <div className="recent-activity__result-relative">
                      <TableTrending amount={item.result.relative} />
                    </div>
                  </div>

                  {/* <div className="recent-activity__toggle">
                    <TableToggle isActive={openedChart === index} />
                  </div> */}
                </div>

                {/* <AnimatePresence initial={false}>
                  {openedChart === index && (
                    <AnimatedTableChart
                      data={item.chart_data.quotes}
                      points={item.chart_data.entry_and_exit_points}
                      result={item.result.relative}
                    />
                  )}
                </AnimatePresence> */}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="table-note">
          The software did not make any trades yet. You will see your trading
          activity here.
        </p>
      )}
    </section>
  );
}
