import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import DeversifyResults from "../deversify-results/deversify-results";
import DeversifyCanvas from "../canvases/deversify-canvas";
import { AccountName, AppRoute, AuthStatus, RERENDER_DELAY } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { displayResultsAmount } from "../../utils/results";
import { setAuthModalOpened } from "../../store/actions";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import {
  QuotesItemsType,
  StrategyResultsForDiversifiedDemoType,
} from "../../types/details";

export default function DeversifyBlock(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());
  const { diversifiedDemo, authStatus } = useAppSelector((state) => state);
  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  const handleStartClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
      return;
    }
    navigate(AppRoute.Overview);
  };

  const quotes = diversifiedDemo?.quotes as QuotesItemsType;
  const results =
    diversifiedDemo?.strategy_results as StrategyResultsForDiversifiedDemoType;
  const sharpeRatio = results.sharpe_ratio;

  const sentence =
    results &&
    sharpeRatio !== null &&
    `According to historical results since 01.01.2021, a portfolio of two
  simultaneous strategies has a Sharpe ratio of ${displayResultsAmount(
    sharpeRatio
  )}, which indicates a more
  efficient use of capital to generate returns, taking into account the
  associated risks.`;

  const intro = (
    <p className="deversify-block__info">
      By using both Binance Spot and Binance Futures, you strengthen your
      results as you will create a more resilient and profitable portfolio.{" "}
      {sentence}
    </p>
  );

  return (
    <section className="landing__item deversify-block">
      <h2 className="landing__title">Or diversify your portfolio</h2>
      {!isDesktop && intro}
      <div className="deversify-block__wrapper">
        <div className="deversify-block__chart">
          <DeversifyCanvas
            type={`${AccountName.BinanceSpot}-${AccountName.BinanceFutures}`}
            isBitcoinMode={isBitcoinMode}
            setBitcoinMode={setBitcoinMode}
            data={quotes}
          />
        </div>
        <div className="deversify-block__results">
          {isDesktop && intro}
          <DeversifyResults isBitcoinMode={isBitcoinMode} />
        </div>
      </div>
      <Button
        classElement="deversify-block__btn"
        pattern="white-blue"
        text="Start trading"
        icon="arrow-right"
        type="button"
        size="big"
        handleClick={handleStartClick}
      />
    </section>
  );
}
