import { useEffect } from "react";

import ReferralsHeading from "../../components/referrals-heading/refferals-heading";
import ReferralsLink from "../../components/referrals-link/referrals-link";
import ReferralsBalance from "../../components/referrals-balance/referrals-balance";
import ReferralsClients from "../../components/referrals-clients/referrals-clients";
import ReferralsActivity from "../../components/referrals-activity.tsx/referrals-activity";
import ReferralsIntro from "../../components/referrals-intro/referrals-intro";
import ReferralsWithdraw from "../../components/referrals-withdraw/referrals-withdraw";
import ReferralsIntroSkeleton from "../../components/referrals-intro/referrals-intro-skeleton";
import ReferralsHeadingSkeleton from "../../components/skeletons/referrals-heading-skeleton";
import { AffiliatesSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchReferrals } from "../../store/api-actions";
import { AuthStatus } from "../../const";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { localAmount } from "../../utils/amount";
import {
  BalanceDetailsType,
  ReferralsActivityType,
  ReferralsItemType,
} from "../../types/referrals";

export default function AffiliatesScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    authStatus,
    isFirstScreenRendered,
    userProfile,
    isUserProfileLoading,
    isReferralsLoading,
    referrals,
  } = useAppSelector((state) => state);

  const clientId = userProfile?.clientId as number;
  const refLink = referrals?.ref_link as string;
  const balance = referrals?.balance_details as BalanceDetailsType;
  const currentBalance = referrals?.balance_details?.current_balance as number;
  const affiliatesAmount = referrals?.balance_details?.referrals as number;
  const referralsList = referrals?.referrals as ReferralsItemType[];
  const activityList = referrals?.activity as ReferralsActivityType[];
  const rate = referrals?.affiliate_rate as number;

  const commission = `${localAmount(rate * 100)}%`;
  const isLoading = isReferralsLoading || isUserProfileLoading;
  const isIntroShown = affiliatesAmount === 0;
  const isNoIntro = referrals !== null && affiliatesAmount !== 0;

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main referrals">
      <AffiliatesSeoSetting />
      <TrackTwitter />
      <h1 className="visually-hidden">Affiliates</h1>

      {isLoading ? (
        <ReferralsHeadingSkeleton />
      ) : (
        isIntroShown && (
          <ReferralsHeading isAffiliate={true} commission={commission} />
        )
      )}

      <div
        className={`referrals__main-wrapper ${
          isNoIntro ? "referrals__main-wrapper--no-intro" : ""
        }`}
      >
        {isLoading ? (
          <ReferralsIntroSkeleton />
        ) : (
          isIntroShown && (
            <ReferralsIntro isAffiliate={true} commission={commission} />
          )
        )}

        <ReferralsLink
          isAffiliate={true}
          isLoading={isLoading}
          refLink={refLink}
        />

        <ReferralsBalance
          authStatus={authStatus}
          isLoading={isLoading}
          isAffiliate={true}
          balance={balance}
          commission={commission}
        />

        {!isUserProfileLoading && !isReferralsLoading && (
          <>
            <ReferralsWithdraw
              currentBalance={currentBalance}
              clientId={clientId}
            />
            <div className="referrals__table-wrapper">
              <ReferralsClients
                isAffiliate={true}
                authStatus={authStatus}
                referralsList={referralsList}
              />
              <ReferralsActivity
                authStatus={authStatus}
                isAffiliate={true}
                activityList={activityList}
              />
            </div>
          </>
        )}
      </div>
    </main>
  );
}
