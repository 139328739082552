import BlueAccountsWithGearIcon from "../../icons/blue/blue-icons-with-gear-icon";
import PortfolioInArrowCircleIcon from "../../icons/blue/portfolio-in-arrow-circle-icon";
import PortfolioWithArrowsIcon from "../../icons/blue/portfolio-with-arrows-icon";
import PlusItem from "./plus-item";

export default function BitgetPluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">How the strategy works</h2>

      <ul className="pluses pluses--bitget pluses--tablet-3-columns">
        <PlusItem
          type="diversified"
          icon={<PortfolioWithArrowsIcon />}
          title="Construction of a diversified portfolio"
          description={
            <p className="pluses__info">
              Diversification is achieved through 30 liquid futures contracts,
              each limited to 1/30th of total capital.
            </p>
          }
        />

        <PlusItem
          type="flexible"
          icon={<PortfolioInArrowCircleIcon />}
          title="Flexible portfolio rebalancing"
          description={
            <p className="pluses__info">
              The strategy employs momentum effect, periodically adjusting the
              portfolio based on probability statistics.
            </p>
          }
        />

        <PlusItem
          type="independence"
          icon={<BlueAccountsWithGearIcon />}
          title="Independence from market trends"
          description={
            <p className="pluses__info">
              The strategy trades long and short positions, allowing it to
              benefit from both bullish and bearish market conditions.
            </p>
          }
        />
      </ul>
    </section>
  );
}
