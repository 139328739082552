import { Link } from "react-router-dom";

import { ResponseStatusType } from "../../../types/details";
import { AppRoute } from "../../../const";

type BinanceLoadAmountNoteProps = {
  loadAmountToStartStatus: null | ResponseStatusType;
};

export default function BinanceLoadAmountNote({
  loadAmountToStartStatus,
}: BinanceLoadAmountNoteProps): JSX.Element {
  return (
    <>
      {loadAmountToStartStatus && loadAmountToStartStatus.message}
      <span className="message__note">
        See our manual on how to successfully
      </span>
      <Link className="link link--underline" to={AppRoute.BinanceSetup}>
        connect to Binance
      </Link>
    </>
  );
}
