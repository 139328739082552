import { Link } from "react-router-dom";
import { AppRoute } from "../../const";

export default function ScrammersNote(): JSX.Element {
  return (
    <p className="scrammers-note">
      SMARD operates exclusively through{" "}
      <Link className="link" to={AppRoute.Manual}>
        https://smard.club/
      </Link>
      . Beware of scammers posing as our representatives
      and&nbsp;asking&nbsp;for&nbsp;fund&nbsp;transfers.
    </p>
  );
}
