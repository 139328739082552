import BinanceLoadAmountNote from "../notes/binance-load-amount-note";
import Message from "../../message/message";
import { ResponseStatusType } from "../../../types/details";
import { ErrorType } from "../../../const";

type Step2MessageProps = {
  loadAmountToStartStatus: null | ResponseStatusType;
  isKeyLengthError: boolean;
};

export default function Step2Message({
  loadAmountToStartStatus,
  isKeyLengthError,
}: Step2MessageProps): JSX.Element {
  return isKeyLengthError ? (
    <Message status="error" message={ErrorType.KeyLength} />
  ) : (
    <Message
      status="error"
      message={
        <BinanceLoadAmountNote loadAmountToStartStatus={loadAmountToStartStatus} />
      }
    />
  );
}
