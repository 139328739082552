import { Dispatch, SetStateAction, useEffect, useState } from "react";

import ConnectStep1 from "./steps/connect-step1";
import ConnectStep2 from "./steps/connect-step2";
import ConnectStep3 from "./steps/connect-step3";
import { ConnectStepPoints } from "../../const";
import { useAppDispatch } from "../../hooks";
import { KeyValuesType } from "../../types/details";
import {
  loadAmountToStart,
  setLoadAmountToStartStatus,
  setSendAmountToStartStatus,
} from "../../store/actions/start";

type StepsToConnectProps = {
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

export default function StepsToConnect({
  activeStep,
  setActiveStep,
  // эти пропы нужны, так как фокусировка в мобилке при выезде блоков на первой кнопке ломала плавную анимацию, а вследствие с этим, и пунктирные линии
  isStepsAnimationEnd,
}: StepsToConnectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [keyValues, setKeyValues] = useState<null | KeyValuesType>(null);

  useEffect(() => {
    return () => {
      dispatch(loadAmountToStart(null));
      dispatch(setLoadAmountToStartStatus(null));
      dispatch(setSendAmountToStartStatus(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConnectStep1
        pointId={ConnectStepPoints.STEP_POINT1}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isStepsAnimationEnd={isStepsAnimationEnd}
      />
      <ConnectStep2
        pointId={ConnectStepPoints.STEP_POINT2}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setKeyValues={setKeyValues}
      />
      <ConnectStep3
        pointId={ConnectStepPoints.STEP_POINT3}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        keyValues={keyValues}
      />
    </>
  );
}
