import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../button/button";
import ConnectPoint3 from "../points/connect-point3";
import Step3Fields from "../fields/step3-fields";
import Step3Message from "../messages/step3-message";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ConnectSteps } from "../../../const";
import { KeyValuesType } from "../../../types/details";
import { formatedMinNotional } from "../../../utils/env";
import { sendAmountToStart } from "../../../store/api-actions/start";

type ConnectStep3Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  keyValues: null | KeyValuesType;
};

export default function ConnectStep3({
  pointId,
  activeStep,
  setActiveStep,
  keyValues,
}: ConnectStep3Props): JSX.Element {
  const dispatch = useAppDispatch();
  const startBtnRef = useRef<HTMLButtonElement | null>(null);

  const {
    strategyDetails,
    sendAmountToStartStatus,
    amountToStart,
    isSendAmountForStartLoading,
  } = useAppSelector((state) => state);
  const accountName = strategyDetails?.overview?.code as string;
  const amount = amountToStart?.usdt as number;
  const sendAmountFailed = sendAmountToStartStatus?.isSuccess === false;

  const [availableAmount, setAvailableAmount] = useState<number>(amount);

  useEffect(() => {
    if (amount || amount === 0) {
      setAvailableAmount(Math.floor(amount));
    }
  }, [amount]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setFocus,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const isStepActive = activeStep === ConnectSteps.STEP3;
  const blockClass = isStepActive ? "block--active" : "block--disabled";
  const backDisabledClass = isSendAmountForStartLoading ? "btn--disabled" : "";
  const submitDisabledClass =
    (!isValid && isStepActive) || isSendAmountForStartLoading
      ? "btn--disabled"
      : "";

  // onSubmit
  const onSubmit = () => {
    const data = getValues(["amount"]);
    const [amount] = data;
    dispatch(
      sendAmountToStart({
        id: accountName,
        key: keyValues?.key as string,
        secret: keyValues?.secret as string,
        amount,
        currency: "usdt",
      })
    );
  };

  const handleBackClick = () => {
    setActiveStep(ConnectSteps.STEP2);
    setValue("amount", "");
  };

  // Действия при переходе на 3й блок
  useEffect(() => {
    if (isStepActive) {
      setFocus("amount");
      setValue("amount", availableAmount);
      trigger("amount");
    }
  }, [availableAmount, isStepActive, setFocus, setValue, trigger]);

  return (
    <form
      className={`details__item connect-step connect-step--final block block--step ${blockClass}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 className="block__heading">
        Specify the amount to&nbsp;be&nbsp;traded&nbsp;by&nbsp;SMARD
      </h3>
      <p className="connect-step__intro">
        {`We will only use the amount you specify for trading. Minimum ${formatedMinNotional} USDT.`}
      </p>
      <div className="connect-step__fields-wrapper">
        <Step3Fields
          availableAmount={availableAmount}
          isSendAmountForStartLoading={isSendAmountForStartLoading}
          sendAmountFailed={sendAmountFailed}
          setValue={setValue}
          trigger={trigger}
          register={register}
        />

        {/* amount message */}
        <div className="connect-step__message">
          {isStepActive && (
            <Step3Message
              sendAmountFailed={sendAmountFailed}
              availableAmount={availableAmount}
            />
          )}
        </div>
      </div>

      {/* buttons */}
      <div className="connect-step__btn-wrapper">
        <Button
          classElement={backDisabledClass}
          pattern="blue-white"
          text="Back"
          icon="arrow-left"
          type="button"
          handleClick={handleBackClick}
        />
        <Button
          classElement={submitDisabledClass}
          pattern="white-blue"
          text="Start"
          icon={isSendAmountForStartLoading ? "arrow-loader" : "arrow-right"}
          type="submit"
          ref={startBtnRef}
        />
      </div>
      <ConnectPoint3 pointId={pointId} isPointActive={isStepActive} />
    </form>
  );
}
