export default function PortfolioInArrowCircleIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="#60A5FA"
        d="M13.13 4.62a8.56 8.56 0 0 0-8.52 8.5 8.56 8.56 0 0 0 8.52 8.52 8.56 8.56 0 0 0 8.5-8.51 8.56 8.56 0 0 0-8.5-8.51Z"
      />
      <path
        fill="#2563EB"
        d="M21.64 13.13a8.56 8.56 0 0 1-8.52 8.5V4.63a8.56 8.56 0 0 1 8.52 8.5Z"
      />
      <path
        fill="#60A5FA"
        d="M20.1 13.13a7.02 7.02 0 0 1-6.98 6.97 7.02 7.02 0 0 1-6.97-6.98 7.02 7.02 0 0 1 6.97-6.97 7.02 7.02 0 0 1 6.98 6.97Z"
      />
      <path
        fill="#2563EB"
        d="M20.1 13.13a7.02 7.02 0 0 1-6.98 6.97V6.15a7.02 7.02 0 0 1 6.98 6.97Z"
      />
      <path
        fill="#60A5FA"
        d="M26.25 13.13a13.2 13.2 0 0 1-2.88 8.21l-1.2-.95a11.63 11.63 0 0 0-9.05-18.85A11.63 11.63 0 0 0 1.54 13.13c0 6.36 5.22 11.58 11.59 11.58 2.03 0 4-.54 5.76-1.55l.82 1.34A13.17 13.17 0 0 1 0 13.13C0 5.9 5.91 0 13.13 0c7.2 0 13.12 5.91 13.12 13.13Z"
      />
      <path
        fill="#2563EB"
        d="m18.9 23.16.81 1.34a13.2 13.2 0 0 1-6.59 1.75v-1.54c2.04 0 4.02-.53 5.77-1.55ZM26.25 13.13a13.2 13.2 0 0 1-2.88 8.21l-1.2-.95a11.63 11.63 0 0 0-9.05-18.85V0c7.22 0 13.13 5.91 13.13 13.13Z"
      />
      <path
        fill="#2563EB"
        d="M26.25 21.64h-4.61v-4.62h1.53v3.08h3.08v1.54ZM13.92 12.53v1.06a9.48 9.48 0 0 0 3.16-.64 3.9 3.9 0 0 0 1.05-.61V11.1c0-.3-.24-.56-.53-.56h-.87v-.57h-.57V8.94c0-.52-.4-.94-.88-.94h-2.15v.75h2.15c.1 0 .17.09.17.2v1.03h-.57v.57h-1.75l-.01 1.98h.8Z"
      />
      <path
        fill="#60A5FA"
        d="M13.92 12.53v1.06a9.48 9.48 0 0 0 3.16-.64 3.9 3.9 0 0 0 1.05-.61V11.1c0-.3-.24-.56-.53-.56h-.87v-.57h-.57V8.94c0-.52-.4-.94-.88-.94h-2.15v.75h2.15c.1 0 .17.09.17.2v1.03h-.57v.57h-1.75l-.01 1.98h.8Z"
      />
      <path
        fill="#2563EB"
        d="M10.98 8c-.48 0-.88.42-.88.94v1.04h-.57v.57h-.87c-.3 0-.53.25-.53.56v1.34c.5.33 1.8 1.01 4.2 1.14v-1.06h.79l.01-1.98h-1.75v-.57h-.57V8.94c0-.1.07-.19.17-.19h2.15V8h-2.15Z"
      />
      <path
        fill="#fff"
        d="M10.98 8c-.48 0-.88.42-.88.94v1.04h-.57v.57h-.87c-.3 0-.53.25-.53.56v1.34c.5.33 1.8 1.01 4.2 1.14v-1.06h.79l.01-1.98h-1.75v-.57h-.57V8.94c0-.1.07-.19.17-.19h2.15V8h-2.15ZM10.32 14v4h2.85l-.05-2.64h-.05c-.41 0-.75-.36-.75-.8v-.3c-.77-.04-1.43-.14-2-.26Z"
      />
      <path
        fill="#60A5FA"
        d="M16.02 18v-4.03c-.58.14-1.28.24-2.1.29v.3c0 .44-.34.8-.75.8h-.05l.05 2.64h2.85Z"
      />
      <path stroke="#fff" d="M13.13 18v-2.7" />
      <path
        fill="#fff"
        d="M8.66 18H9.7v-4.15c-.7-.2-1.22-.44-1.58-.64v4.22c0 .32.24.57.53.57Z"
      />
      <path
        fill="#60A5FA"
        d="M18.13 17.43v-4.29c-.32.2-.8.45-1.5.67V18h.97c.3 0 .53-.25.53-.57Z"
      />
    </svg>
  );
}
