import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { StateAction } from "../action-types";
import { ThunkApiConfigType } from "../../types/api";
import { fetchStrategyDetails } from "../api-actions";
import { setRenewKeysOpened } from "../actions";
import {fetchAmountToStartBitget, fetchAmountToStartBybit, fetchAmountToStartOkx} from "./start";
import { ApiRoute, AccountActionPath, ErrorType } from "../../const";
import {
    ConfIntegrationBybitType,
    ConfIntegrationOkxType,
    ConfIntegrationType,
} from "../../types/details";
import {
  setAddKeysLoading,
  setAddKeysStatus,
  setSendRenewKeysToCheckLoading,
  setSendRenewKeysToCheckStatus,
  setSendRenewKeysToUpdateLoading,
  setSendRenewKeysToUpdateStatus,
} from "../actions/keys";

// ADD KEYS FOR OKX "STEPS TO CONNECT"
export const addKeysOkx = createAsyncThunk<
  void,
  ConfIntegrationOkxType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret, passphrase }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
          passphrase,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(fetchAmountToStartOkx());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 401
      ) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);

// ADD KEYS FOR OKX "RENEW KEYS"
export const updateRenewKeysOkx = createAsyncThunk<
  void,
  ConfIntegrationOkxType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret, passphrase }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
          passphrase,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(setRenewKeysOpened(false));
      dispatch(fetchStrategyDetails(id));
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);

// ADD KEYS FOR BITGET "STEPS TO CONNECT"
export const addKeysBitget = createAsyncThunk<
  void,
  ConfIntegrationOkxType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret, passphrase }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
          passphrase,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(fetchAmountToStartBitget());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 401
      ) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);


// ADD KEYS FOR BYBIT "STEPS TO CONNECT"
export const addKeysBybit = createAsyncThunk<
  void,
  ConfIntegrationBybitType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(fetchAmountToStartBybit());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 401
      ) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);


// ADD KEYS FOR BITGET "RENEW KEYS"
export const updateRenewKeysBitget = createAsyncThunk<
  void,
  ConfIntegrationOkxType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret, passphrase }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
          passphrase,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(setRenewKeysOpened(false));
      dispatch(fetchStrategyDetails(id));
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);

// ADD KEYS FOR BYBIT "RENEW KEYS"
export const updateRenewKeysBybit = createAsyncThunk<
  void,
  ConfIntegrationBybitType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetAddKeysStatus,
  async ({ id, key, secret }, { dispatch, extra: api }) => {
    dispatch(setAddKeysLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.ADD_KEYS}`,
        {
          key,
          secret,
        }
      );
      dispatch(
        setAddKeysStatus({
          isSuccess: true,
          message: "",
        })
      );
      dispatch(setRenewKeysOpened(false));
      dispatch(fetchStrategyDetails(id));
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setAddKeysStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: err.response?.data.msg,
          })
        );
      }
    }
    dispatch(setAddKeysLoading(false));
  }
);

// CHECK KEYS FOR BINANCE "RENEW KEYS" ACTION 1
export const checkRenewKeys = createAsyncThunk<
  void,
  ConfIntegrationType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendRenewKeysToCheckStatus,
  async ({ id, key, secret }, { dispatch, extra: api }) => {
    dispatch(setSendRenewKeysToCheckLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.COIN_INFO}`,
        {
          key,
          secret,
        }
      );
      dispatch(updateRenewKeys({ id, key, secret }));
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.status === 400 &&
        err.response?.data.detail
      ) {
        dispatch(
          setSendRenewKeysToCheckStatus({
            isSuccess: false,
            message: err.response?.data.detail,
          })
        );
      } else {
        dispatch(
          setSendRenewKeysToCheckStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendRenewKeysToCheckLoading(false));
  }
);

// UPDATE KEYS FOR BINANCE "RENEW KEYS" ACTION 2
export const updateRenewKeys = createAsyncThunk<
  void,
  ConfIntegrationType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendRenewKeysToUpdateStatus,
  async ({ id, key, secret }, { dispatch, extra: api }) => {
    dispatch(setSendRenewKeysToUpdateLoading(true));

    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.RENEW}`,
        {
          key,
          secret,
        }
      );
      dispatch(setRenewKeysOpened(false));
      dispatch(fetchStrategyDetails(id));
    } catch {
      dispatch(
        setSendRenewKeysToUpdateStatus({
          isSuccess: false,
          message: "",
        })
      );
    }
    dispatch(setSendRenewKeysToUpdateLoading(false));
  }
);
