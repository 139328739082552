export default function TrendingUpIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 10"
    >
      <path
        fill="currentColor"
        d="m11.8.73 1.18 1.25-4 4.2L6.3 3.36a.78.78 0 0 0-1.16 0L.24 8.53a.9.9 0 0 0 0 1.22c.32.33.83.33 1.15 0l4.33-4.57L8.4 8c.31.34.83.34 1.15 0l4.57-4.81 1.17 1.24c.26.27.7.08.7-.3V.44a.4.4 0 0 0-.4-.44h-3.5c-.38 0-.56.47-.3.73Z"
      />
    </svg>
  );
}
