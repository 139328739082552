import { useState } from "react";
import { useLocation } from "react-router-dom";

import AuthDescription from "../../auth-description/auth-description";
import { useAppSelector } from "../../../hooks";
import { AppRoute, AuthStatus, RERENDER_DELAY } from "../../../const";
import ScrammersNote from "../../scrammers-note/scrammers-note";
import { debounce } from "../../../utils/chart/chart";
import { checkIfTabletAndMore } from "../../../utils/breakpoint";

export default function ShortHeaderExtra(): JSX.Element {
  const { pathname } = useLocation();
  const { authStatus } = useAppSelector((state) => state);
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const hideExtraHeader = !isNoAuth && pathname.includes(AppRoute.Coins);
  const isManual = pathname === AppRoute.Manual;

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  return (
    <div className="short-header-extra">
      <div className="short-header__container">
        {hideExtraHeader ? null : (
          <div className="short-header-extra__wrapper">
            {isManual && isTabletAndMore && <ScrammersNote />}
            {isNoAuth && <AuthDescription />}
          </div>
        )}
      </div>
    </div>
  );
}
