import BlueDollarArrowsRightIcon from "../icons/blue-icon/blue-dollar-arrows-right-icon";
import BlueDollarInDropIcon from "../icons/blue-icon/blue-dollar-in-drop-icon";
import BluePercentDownIcon from "../icons/blue-icon/blue-percent-down-icon";
import PlusItem from "./plus-item";

export default function OkxPluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Explore OKX's trading opportunities</h2>
      <ul className="pluses pluses--okx pluses--tablet-3-columns">
        <PlusItem
          type="liquid"
          icon={<BlueDollarInDropIcon />}
          title="Reputable & Highly Liquid"
          description={
            <p className="pluses__info">
              OKX stands as one of the largest and most reputable exchanges in
              the world, offering high liquidity.
            </p>
          }
        />
        <PlusItem
          type="lower"
          icon={<BluePercentDownIcon />}
          title="Lower Trading Fees and Rebates"
          description={
            <p className="pluses__info">
              By joining OKX as our referral, clients will enjoy reduced trading
              fees on every transaction made by SMARD.
            </p>
          }
        />

        <PlusItem
          type="diversification"
          icon={<BlueDollarArrowsRightIcon />}
          title="Counterparty diversification"
          description={
            <p className="pluses__info">
              Make your portfolio more resilient to potential defaults. Don't
              keep all your eggs in one basket.
            </p>
          }
        />
      </ul>
    </section>
  );
}
