import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import LogoIcon from "../icons/logo-icon";
import PlusIcon from "../icons/sign/plus-icon";
import BitgetIcon from "../icons/bitget-icon";
import { createBitgetConnectLink } from "../../utils/id";
import { setAuthModalOpened } from "../../store/actions";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  BITGET_REGISTER_LINK,
} from "../../const";

export default function BitgetPromo(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isBitgetConnected, authStatus } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const bitgetConnectLink = createBitgetConnectLink();

  const handleRegisterClick = () => {
    trackEvent("BitgetLandingRegisterAccount", "Clicked");
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("BitgetLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`);
    } else {
      dispatch(setAuthModalOpened(true));
    }
  };

  return (
    <section className="landing__item bitget-promo">
      <div className="bitget-promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">
            Maximize your trading potential
          </span>{" "}
          on <span className="landing__heading-large">Bitget</span> with&nbsp;
          <span className="landing__heading-large">SMARD</span>
        </h1>
      </div>
      <p className="bitget-promo__description">
        Explore the advantages of trading with SMARD on Bitget. Our strategic
        collaboration offers you a gateway to efficient automated trading. Start
        trading on Bitget to access our top&#8209;performing&nbsp;strategies.
      </p>
      <div className="bitget-promo__icons">
        <span className="bitget-promo__icon bitget-promo__icon--smard">
          <LogoIcon />
        </span>
        <span className="bitget-promo__icon bitget-promo__icon--plus">
          <PlusIcon />
        </span>
        <span className="bitget-promo__icon bitget-promo__icon--bitget">
          <BitgetIcon />
        </span>
      </div>

      <div className="bitget-promo__btns">
        {isBitgetConnected === null ? (
          <div className="landing__btn-gap"></div>
        ) : isBitgetConnected ? (
          <Button
            pattern="white-blue"
            text="View trading results"
            type="button"
            icon="eye"
            size="super"
            handleClick={handleViewClick}
          />
        ) : (
          <>
            <Button
              pattern="white-blue"
              text="Register Bitget account"
              icon="key-in-circle"
              type="link"
              size="super-big"
              linkAddress={BITGET_REGISTER_LINK}
              handleClick={handleRegisterClick}
            />
            <Button
              pattern="blue-white"
              text="Connect an existing account"
              icon="check"
              type={isNoAuth ? "button" : "link"}
              size="huge"
              linkAddress={bitgetConnectLink}
              isSelfLink
              handleClick={handleConnectClick}
            />
          </>
        )}
      </div>
    </section>
  );
}
