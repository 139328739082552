import Hint from "../hint/hint";
import { useAppSelector } from "../../hooks";
import {
  AuthStatus,
  StrategyStatus,
  HINTS_TEXT,
  EMPTY_DATA,
  AccountName,
} from "../../const";
import {
  ComissionsType,
  RecentActivityType,
  StrategyDetailsType,
  StrategyResultsType,
} from "../../types/details";
import {
  displayResultsAbsolute,
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

type TradingResultsProps = {
  isBitcoin: boolean;
  strategyDetails: StrategyDetailsType;
};

export default function TradingResults({
  isBitcoin,
  strategyDetails,
}: TradingResultsProps): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);

  const results = strategyDetails?.strategy_results as StrategyResultsType;
  const commissions = results?.commissions as ComissionsType;
  const activity = strategyDetails?.recent_activity as RecentActivityType[];
  const status = strategyDetails?.status as string;
  const currency = strategyDetails?.overview.currency as string;
  const accountName = strategyDetails?.overview.code as string;
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isOkx = accountName === AccountName.Okx;
  const isBinanceFutures = accountName === AccountName.BinanceFutures;
  const isBitget = accountName === AccountName.BitgetFutures;

  const hint = (
    <Hint
      type="block__hint"
      id="details/strategy-results"
      hintText={HINTS_TEXT["details/strategy-results"]}
      block="trading-results"
    />
  );

  return (
    <section className="details__item details__results trading-results block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">
          {status === StrategyStatus.NotStarted ||
          status === StrategyStatus.Demo
            ? "Historical results"
            : "Trading results"}
        </h2>

        {isNoAuth && hint}
      </div>

      <ul className="trading-results__table">
        <li className="trading-results__row">
          <p className="trading-results__name">Sharpe ratio</p>
          <p className="trading-results__value">
            {displayResultsAmount(results.sharpe_ratio)}
          </p>
        </li>
        <li className="trading-results__row">
          <p className="trading-results__name">Annualized return</p>
          <p className="trading-results__value">
            {displayResultsPercent(results.annualized_return)}
          </p>
        </li>
        <li className="trading-results__row">
          <p className="trading-results__name">Average monthly return</p>
          <p className="trading-results__value">
            {displayResultsPercent(results.avg_monthly_return)}
          </p>
        </li>
        {isOkx || isBinanceFutures || isBitget ? (
          <>
            <li className="trading-results__row">
              <p className="trading-results__name">Return</p>
              <p className="trading-results__value">
                {displayResultsAbsolute(results.total_return_abs, currency)}
              </p>
            </li>
            <li className="trading-results__row">
              <p className="trading-results__name">Max drawdown</p>
              <p className="trading-results__value">
                {displayResultsPercent(results.mdd)}
              </p>
            </li>
          </>
        ) : null}

        <li className="trading-results__row">
          <p className="trading-results__name">
            {`Return vs ${isBitcoin ? "BTC" : "ETH"}`}
          </p>
          <p className="trading-results__value">
            {isBitcoin
              ? displayResultsPercent(results.return_vs_btc)
              : displayResultsPercent(results.return_vs_eth)}
          </p>
        </li>
        {status === StrategyStatus.Started ? (
          <li className="trading-results__row">
            <p className="trading-results__name">Exchange commissions paid</p>
            <p className="trading-results__value">
              {commissions
                ? !activity.length
                  ? displayResultsPercent(commissions.relative)
                  : displayResultsAbsolute(commissions.absolute, currency)
                : EMPTY_DATA}
            </p>
          </li>
        ) : null}
      </ul>
    </section>
  );
}
