export default function PortfolioWithArrowsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 34 28"
    >
      <path
        fill="#2563EB"
        d="M27 15.75v-4.5h2.71V9L34 13.5 29.71 18v-2.25H27ZM23.6 23.5l3.17-3.18 1.92 1.92 1.6-1.6-.16 6.22-6.21.15 1.59-1.6-1.92-1.91ZM26.77 6.72 23.6 3.54l1.92-1.92L23.92.03l6.21.15.15 6.22-1.59-1.6-1.92 1.92Z"
      />
      <path
        fill="#2563EB"
        d="M12.74 10.96v2.34c3.33-.17 5.58-.84 6.95-1.4A8.58 8.58 0 0 0 22 10.53v-2.7c0-.68-.52-1.24-1.16-1.24h-1.93V5.36h-1.25V3.08c0-1.15-.87-2.08-1.93-2.08H11v1.66h4.73c.21 0 .38.19.38.42v2.28h-1.25V6.6H11l-.02 4.36h1.76Z"
      />
      <path
        fill="#2563EB"
        d="M12.74 10.96v2.34c3.33-.17 5.58-.84 6.95-1.4A8.58 8.58 0 0 0 22 10.53v-2.7c0-.68-.52-1.24-1.16-1.24h-1.93V5.36h-1.25V3.08c0-1.15-.87-2.08-1.93-2.08H11v1.66h4.73c.21 0 .38.19.38.42v2.28h-1.25V6.6H11l-.02 4.36h1.76Z"
      />
      <path
        fill="2563EB"
        d="M6.27 1c-1.06 0-1.93.93-1.93 2.08v2.28H3.1V6.6H1.16C.52 6.6 0 7.16 0 7.85v2.95c1.09.72 3.95 2.22 9.22 2.5v-2.34h1.76L11 6.6H7.14V5.36H5.89V3.08c0-.23.17-.42.38-.42H11V1H6.27Z"
      />
      <path
        fill="#60A5FA"
        d="M6.27 1c-1.06 0-1.93.93-1.93 2.08v2.28H3.1V6.6H1.16C.52 6.6 0 7.16 0 7.85v2.95c1.09.72 3.95 2.22 9.22 2.5v-2.34h1.76L11 6.6H7.14V5.36H5.89V3.08c0-.23.17-.42.38-.42H11V1H6.27ZM4.82 14.2V23h6.28l-.12-5.81h-.1c-.92 0-1.66-.8-1.66-1.78v-.66a27.82 27.82 0 0 1-4.4-.55Z"
      />
      <path
        fill="#2563EB"
        d="M17.37 23v-8.87c-1.29.3-2.82.53-4.63.62v.66c0 .98-.74 1.78-1.66 1.78h-.1l.12 5.8h6.27Z"
      />
      <path stroke="#000" d="M11 23v-5.96" />
      <path
        fill="#60A5FA"
        d="M1.16 23h2.31v-9.14A16.1 16.1 0 0 1 0 12.47v9.28C0 22.45.52 23 1.16 23Z"
      />
      <path
        fill="#2563EB"
        d="M22 21.76V12.3c-.7.44-1.76.99-3.28 1.46V23h2.12c.64 0 1.16-.56 1.16-1.24Z"
      />
      <path stroke="#A0CBFF" d="M11 23v-5.96" />
    </svg>
  );
}
