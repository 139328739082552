import { AccountName } from "../../const";
import { ChartType } from "../../types/chart";
import { StrategyResultsType } from "../../types/details";
import { DemoType, DemoTypeMap, SelectedAccountNames } from "../../types/general";
import { StrategyDataForChartType } from "../../types/strategy";
import { formatChartAmounts, localAmount } from "../amount";

export const formatOverviewChartData = (data: number[]) => {
  const formattedData: StrategyDataForChartType[] = [];

  data.forEach((amount, index) => {
    const obj = {
      timestamp: index,
      amount: amount,
    };
    formattedData.push(obj);
  });
  //  if less days then in month for short chart
  if (data.length < 30) {
    for (let i = data.length; i <= 30; i++) {
      const obj = {
        timestamp: i,
      };
      formattedData.push(obj);
    }
  }

  return formattedData;
};

export const sortDemoByProfit = (initialOrder: SelectedAccountNames[], data: DemoTypeMap) => {
  return initialOrder.slice().sort((a, b) => {
    const returnA = data[a].strategy_results.annualized_return || 0;
    const returnB = data[b].strategy_results.annualized_return || 0;
    return returnB - returnA;
  });
};


export const sortAmounts = (a: number, b: number) => {
  return a - b;
};

export const findMinMax = (amounts: number[]) => {
  const sortedAmounts = [...amounts].sort(sortAmounts);

  // for lower chart fill if amount is not changed
  if (amounts.every((item) => amounts[0] === item)) {
    return [amounts[0] * 0.9, amounts[0] * 1.1];
  }

  return [sortedAmounts[0], sortedAmounts[sortedAmounts.length - 1]];
};

export const debounce = (callback: Function, timeoutDelay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, args), timeoutDelay);
  };
};

export const collectAmounts = (data: ChartType[]) => {
  const formattedData: number[] = [];

  if (data) {
    data.forEach(({ amount }) => {
      formattedData.push(amount);
    });
  }

  return formattedData;
};

export const collectDates = (data: ChartType[]) => {
  const formattedData: number[] = [];

  if (data) {
    data.forEach(({ timestamp }) => {
      formattedData.push(timestamp);
    });
  }

  return formattedData;
};

export const findTicks = (min: number, max: number) => {
  const roundedMin = Math.round(min / 10) * 10;
  const roundedMax = Math.round(max / 10) * 10;
  const diff = roundedMax - roundedMin;
  const part = diff / 5;

  const ticks = [];
  let i = roundedMin;
  while (i < roundedMax) {
    ticks.push(i);
    i = i + part;
  }
  ticks.push(roundedMax);

  return ticks;
};

export const findSmallAmountTicks = (min: number, max: number) => {
  if (max - min >= 1) {
    const roundedMin = Number(min.toFixed(2));
    const roundedMax = Number(max.toFixed(2));

    const diff = roundedMax - roundedMin;
    const part = diff / 5;

    const ticks = [];
    let i = roundedMin;
    while (i < roundedMax) {
      ticks.push(i);
      i = i + part;
    }
    ticks.push(Number(roundedMax.toFixed(2)));
    return ticks;
  }

  return [];
};

export const formatTicks = (tick: number) => {
  if (tick < 0) {
    return "";
  }

  return `${localAmount(formatChartAmounts(tick).amount)}${
    formatChartAmounts(tick).short
  }`;
};

export const formatSmallAmountTicks = (tick: number) => {
  if (tick >= 10) {
    return `${localAmount(Math.round(tick))}`;
  }

  if (tick < 0.001) {
    return `${localAmount(tick)}`;
  }

  if (tick < 0.1) {
    return `${localAmount(Number(tick.toFixed(4)))}`;
  }

  return `${localAmount(Number(tick.toFixed(2)))}`;
};

export const checkMinDomain = (main: number, secondary: number) => {
  if (main < 0 || secondary < 0) {
    return 0;
  }

  return main;
};

export const isMore6Months = (months: string[]) => months.length > 6;
