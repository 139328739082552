import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import OverviewList from "../../components/overview-board/overview-list";
import HeadingSkeleton from "../../components/skeletons/heading-skeleton";
import StrategySkeleton from "../../components/skeletons/strategy-skeleton";
import OverviewSummary from "../../components/overview-summary/overview-summary";
import OverviewSummarySkeleton from "../../components/skeletons/overview-summary-skeleton";
import { OverviewSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks/index";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { checkAuthAndRegistration } from "../../utils/params";
import { redirectToExternalLink } from "../../utils/general";
import { createBitgetConnectLink, createOkxConnectLink } from "../../utils/id";
import {
  loadOverviewSummary,
  setOverviewSummaryStatus,
  loadStrategies,
} from "../../store/actions/overview";
import {
  fetchOverviewSummary,
  fetchStrategies,
} from "../../store/api-actions/overview";
import {
  ACCOUNTS_NUMBER,
  AccountName,
  AppRoute,
  AuthStatus,
  ParamsRedirect,
} from "../../const";

export default function OverviewScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isStrategiesLoading,
    isUserProfileLoading,
    userProfile,
    isFirstScreenRendered,
    authStatus,
    isOkxConnected,
    isBitgetConnected,
    overviewSummary,
    isOverviewSummaryLoading,
    overviewSummaryStatus,
  } = useAppSelector((state) => state);

  const isShowSummary = userProfile?.isShowSummary;
  const isUnknown = authStatus === AuthStatus.Unknown;
  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isLoading =
    isStrategiesLoading ||
    isUserProfileLoading ||
    isOverviewSummaryLoading ||
    isUnknown;
  const boardSkeletons = Array(ACCOUNTS_NUMBER)
    .fill("")
    .map((_, i) => <StrategySkeleton key={i} />);

  const displayWelcome =
    userProfile && userProfile.name
      ? `Welcome, ${userProfile.name}!`
      : "Welcome!";

  // Для telegram и google
  useEffect(() => {
    const successAuth = searchParams.get("success_auth");
    const successRegistration = searchParams.get("success_registration");
    const redirectTo = searchParams.get("redirect_to");

    if (
      isOkxConnected !== null &&
      (successAuth || successRegistration || redirectTo)
    ) {
      checkAuthAndRegistration(successAuth, successRegistration);

      if (redirectTo && redirectTo === ParamsRedirect.OKX) {
        if (isOkxConnected) {
          navigate(`${AppRoute.Accounts}/${AccountName.Okx}/`);
          return;
        }
        setSearchParams({});
        redirectToExternalLink(createOkxConnectLink());
      }

      if (redirectTo && redirectTo === ParamsRedirect.BITGET) {
        if (isBitgetConnected) {
          navigate(`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`);
          return;
        }
        setSearchParams({});
        redirectToExternalLink(createBitgetConnectLink());
      }

      setSearchParams({});
    }
  }, [
    dispatch,
    isBitgetConnected,
    isOkxConnected,
    navigate,
    searchParams,
    setSearchParams,
  ]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && isAuth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  useEffect(() => {
    if (isNoAuth) {
      dispatch(fetchStrategies());
    }

    if (isAuth && userProfile) {
      dispatch(fetchStrategies());
    }

    // eslint-disable-next-line
  }, [userProfile, isNoAuth, isAuth]);

  useEffect(() => {
    if (isAuth && isShowSummary && !overviewSummaryStatus) {
      dispatch(fetchOverviewSummary());
    }

    return () => {
      if (isAuth && overviewSummary) {
        dispatch(loadOverviewSummary(null));
        dispatch(setOverviewSummaryStatus(null));
      }
    };
  }, [dispatch, isAuth, isShowSummary, overviewSummary, overviewSummaryStatus]);

  return (
    <main className="main overview">
      <TrackTwitter />
      <OverviewSeoSetting />
      <h1 className="visually-hidden">Overview</h1>
      {userProfile !== null &&
        (isLoading ? (
          <>
            <HeadingSkeleton />
            <div className="overview__wrapper">
              {isShowSummary && isAuth && <OverviewSummarySkeleton />}
              {boardSkeletons}
            </div>
          </>
        ) : (
          <>
            <p className="overview__welcome">{displayWelcome}</p>
            <div className="overview__wrapper">
              {overviewSummary && <OverviewSummary summary={overviewSummary} />}
              <OverviewList />
            </div>
          </>
        ))}
    </main>
  );
}
