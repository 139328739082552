import { useState } from "react";

import TogglesBase from "../toggles/toggles-base";
import BinanceSpotIcon from "../../icons/account/binance-spot-icon";
import BinanceFuturesIcon from "../../icons/account/binance-futures-icon";
import BinanceChart from "../charts/binance-chart";
import { AccountName } from "../../const";
import { QuotesItemType, QuotesItemsType } from "../../types/details";

type BinanceCanvasProps = {
  data: QuotesItemsType;
  type: string;
};

export default function BinanceCanvas({
  data,
  type,
}: BinanceCanvasProps): JSX.Element {
  const [isAllDays, setAllDays] = useState<boolean>(true);
  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);

  const btcData = data.btc as QuotesItemType[];
  const ethData = data.eth as QuotesItemType[];
  const strategyData = data.strategy as QuotesItemType[];

  const chooseIcon = () => {
    switch (type) {
      case AccountName.BinanceFutures:
        return (
          <span className="binance-canvas__icon binance-canvas__icon--binance-futures">
            <BinanceFuturesIcon />
          </span>
        );
      default:
        return (
          <span className="binance-canvas__icon binance-canvas__icon--binance-spot">
            <BinanceSpotIcon />
          </span>
        );
    }
  };

  return (
    <div className="binance-canvas">
      <div className="binance-canvas__logo">{chooseIcon()}</div>
      <BinanceChart
        main={strategyData}
        btcBench={btcData}
        ethBench={ethData}
        isAllDays={isAllDays}
        isBitcoin={isBitcoinMode}
        type={type}
      />
      <TogglesBase
        type={type}
        isBitcoinMode={isBitcoinMode}
        isAllDays={isAllDays}
        setBitcoinMode={setBitcoinMode}
        setAllDays={setAllDays}
      />
    </div>
  );
}
