import { useEffect } from "react";

import OkxPromo from "../../components/promos/okx-promo";
import OkxPluses from "../../components/pluses/okx-pluses";
import OkxSteps from "../../components/steps/okx-steps";
import OkxInsight from "../../components/insights/okx-insight";
import OkxBriefly from "../../components/brieflies/okx-briefly";
import OkxFaq from "../../components/faq/okx-faq";
import Bots from "../../components/bots/bots";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteSeoData } from "../../utils/general";
import { OkxLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { fetchOkxDemo } from "../../store/api-actions/demo";

export default function OkxScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { okxDemo } = useAppSelector((state) => state);

  // any page
  useEffect(() => {
    trackEvent("OKXLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchOkxDemo());
  }, [dispatch]);

  return (
    <main className="main main--landing landing">
      <OkxLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <OkxPromo />
        <OkxPluses />
        <OkxSteps />
        {okxDemo && <OkxInsight />}
        <OkxBriefly />
        <Bots />
        <OkxFaq />
      </div>
    </main>
  );
}
