import { SyntheticEvent, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button/button";
import CloseIcon from "../icons/close-icon";
import { useAppDispatch } from "../../hooks";
import { AppRoute, IS_BYBIT_POPUP_SHOWN } from "../../const";
import { trackEvent } from "../../utils/tracking";
import { setBybiytModalOpened } from "../../store/actions";

export default function BybitModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (evt: SyntheticEvent) => {
    const target = evt.target as Element;
    const parent = target.parentElement;

    if (
      (modalRef.current?.contains(parent as Element) &&
        !closeRef.current?.contains(target as Element)) ||
      (modalRef.current?.contains(target as Element) &&
        !closeRef.current?.contains(target as Element))
    ) {
      return;
    }
    dispatch(setBybiytModalOpened(false));
  };

  useEffect(() => {
    localStorage.setItem(IS_BYBIT_POPUP_SHOWN, "true");
  }, []);

  const handleMainBtnClick = () => {
    navigate(AppRoute.Bybit);
    dispatch(setBybiytModalOpened(false));
    trackEvent("BybitPopupBtn", "Clicked");
  };

  const handleCloseClick = () => {
    dispatch(setBybiytModalOpened(false));
  };

  return (
    <div className="strategy-popup" onClick={handleClick}>
      <div
        className="strategy-popup__wrapper  strategy-popup__wrapper--bybit"
        ref={modalRef}
      >
        <h2 className="strategy-popup__heading strategy-popup__heading--bitget">
          Exclusive Welcome Bonus:
          <br />
          <b className="strategy-popup__attn">10% Discount on Bybit Fees!</b>
        </h2>
        <p className="strategy-popup__message strategy-popup__message--short">
          SMARD is excited to announce the launch on Bybit.
        </p>
        <p className="strategy-popup__message strategy-popup__message--short">
          Bybit is excited as well and reduces its trading fees by 10% for all
          clients coming from SMARD.
        </p>
        <p className="strategy-popup__message strategy-popup__message--short">
          Register with Bybit, start using it with SMARD and enjoy improved
          trading efficiency until September 19, 2024!
        </p>

        <Button
          classElement="strategy-popup__btn"
          pattern="white-blue"
          text="Get Bonus!"
          icon="arrow-right"
          type="button"
          handleClick={handleMainBtnClick}
        ></Button>

        <button
          className="strategy-popup__close"
          aria-label="Close modal"
          type="button"
          ref={closeRef}
          onClick={handleCloseClick}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}
