type ConnectApiLinkProps = {
  accountName: string;
};

export default function ConnectApiLink({
  accountName,
}: ConnectApiLinkProps): JSX.Element {
  const apiLink = "https://www.binance.com/en/my/settings/api-management";

  return (
    <a
      className="link"
      href={apiLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      API Management
    </a>
  );
}
