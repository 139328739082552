import { nanoid } from "nanoid";

const generate16symbols = () => nanoid(16);

export const createOkxConnectLink = () =>
  `https://www.okx.com/ru/account/oauth?access_type=offline&client_id=${
    process.env.REACT_APP_OKX_OAUTH_CLIENT_ID
  }&redirect_uri=${
    process.env.REACT_APP_SITE_ORIGIN
  }/connect/okx/&response_type=code&scope=fast_api&state=${generate16symbols()}`;

export const createBitgetConnectLink = () =>
  "https://smard.club/connect/bitget/";

export const createBybitConnectLink = () =>
  `https://www.bybit.com/oauth?client_id=${
    process.env.REACT_APP_BYBIT_OAUTH_CLIENT_ID
  }&redirect_uri=${
    process.env.REACT_APP_SITE_ORIGIN
  }/connect/bybit/&response_type=code&scope=openapi&state=${generate16symbols()}&affiliate_id=${
    process.env.REACT_APP_BYBIT_AFFILIATE_ID
  }`;