import Button from "../button/button";
import LogoIcon from "../icons/logo-icon";
import PlusIcon from "../icons/sign/plus-icon";
import BinanceSpotIcon from "../../icons/account/binance-spot-icon";
import { setAuthModalOpened } from "../../store/actions";
import { AccountName, AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";

export default function BinancePromo(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const handleConnectBinanceSpotClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
      return;
    }
    navigate(`${AppRoute.Accounts}/${AccountName.BinanceSpot}/`);
  };

  const handleConnectBinanceFuturesClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
      return;
    }
    navigate(`${AppRoute.Accounts}/${AccountName.BinanceFutures}/`);
  };

  return (
    <section className="landing__item binance-promo">
      <div className="binance-promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">Automate your trading</span>{" "}
          on the{" "}
          <span className="landing__heading-large">
            largest cryptocurrency exchange
          </span>
        </h1>
      </div>

      <p className="binance-promo__description">
        Enhance your Binance trading experience effortlessly with our
        strategies. No&nbsp;pre&#8209;configuration or trading knowledge is
        required - portfolio management is fully automated.
        <br />
        You can lower your risk by using Binance Spot or Binance Futures, or
        both, as our strategies differ for spot and futures instruments.
      </p>
      <div className="binance-promo__icons">
        <span className="binance-promo__icon binance-promo__icon--smard">
          <LogoIcon />
        </span>
        <span className="binance-promo__icon binance-promo__icon--plus">
          <PlusIcon />
        </span>
        <span className="binance-promo__icon binance-promo__icon--binance">
          <BinanceSpotIcon />
        </span>
      </div>

      <div className="binance-promo__btns">
        <Button
          pattern="white-blue"
          text="Connect to Binance Spot"
          type="button"
          icon="arrow-right"
          handleClick={handleConnectBinanceSpotClick}
          size="before-huge"
        />

        <Button
          pattern="blue-white"
          text="Connect to Binance Futures"
          type="button"
          icon="arrow-right"
          handleClick={handleConnectBinanceFuturesClick}
          size="huge"
        />
      </div>
    </section>
  );
}
