import MinNotionalError from "../notes/min-notional-error";
import AmountRangeNote from "../notes/amount-rage-note";
import Message from "../../message/message";
import { ErrorType } from "../../../const";
import { minNotional } from "../../../utils/env";

type Step3MessageProps = {
  sendAmountFailed: boolean;
  availableAmount: number;
};

export default function Step3Message({
  sendAmountFailed,
  availableAmount,
}: Step3MessageProps): JSX.Element {
  return sendAmountFailed ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<MinNotionalError />} />
  );
}
