import { useEffect } from "react";
import { useParams } from "react-router-dom";

import DetailsBlocksSkeletons from "../../components/details-blocks/details-blocks-skeletons";
import DetailsBlocks1 from "../../components/details-blocks/details-blocks1";
import DetailsBlocks2 from "../../components/details-blocks/details-blocks2";
import { DetailsSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchStrategyDetails } from "../../store/api-actions";
import { deleteSeoData } from "../../utils/general";
import { createBitgetConnectLink, createOkxConnectLink } from "../../utils/id";
import { redirectToExternalLink } from "../../utils/general";
import { AuthStatus, StrategyStatus } from "../../const";
import { StrategyOverviewType } from "../../types/details";
import {
  dropRedirectAccount,
  getRedirectAccount,
} from "../../services/redirect-account";
import {
  loadStrategyDetails,
  setFirstScreenRendered,
  setIntegrationOpened,
  setStopModalId,
} from "../../store/actions";

export default function DetailsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { accountName } = useParams();

  const {
    isStrategyDetailsLoading,
    isFixDivergenceLoading,
    authStatus,
    isFirstScreenRendered,
    strategyDetails,
    isIntegrationOpened,
    isOkxConnected,
    isOkxRedirectFromApple,
    isBitgetConnected,
    isBitgetRedirectFromApple,
  } = useAppSelector((state) => state);

  const strategyStatus = strategyDetails?.status as string;
  const overview = strategyDetails?.overview as StrategyOverviewType;
  const overviewId = overview?.code as string;

  const isMainSkeletonsShown =
    isStrategyDetailsLoading && !isFixDivergenceLoading;

  const account = getRedirectAccount();
  if (account) {
    dropRedirectAccount();
  }

  useEffect(() => {
    trackEvent(`Details(${accountName})`, "PageView");
    // Отслеживаем изменения данных на одной и той же странице по accountName
  }, [accountName]);

  useEffect(() => {
    dispatch(fetchStrategyDetails(accountName as string));
    return () => {
      dispatch(loadStrategyDetails(null));
      dispatch(setStopModalId(null));
    };
  }, [accountName, dispatch]);

  useEffect(() => {
    return () => {
      // Закрытие модалки при смене страницы и переключения аккаунтов
      // При удачном запуске закрытие модалки - в api старте
      if (isIntegrationOpened) {
        dispatch(setIntegrationOpened(false));
      }
    };
  }, [accountName, dispatch, isIntegrationOpened]);

  useEffect(() => {
    if (overviewId && strategyStatus === StrategyStatus.Started) {
      dispatch(setStopModalId(overviewId));
    }
  }, [dispatch, accountName, overviewId, strategyStatus]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);

  // Для apple
  useEffect(() => {
    if (isOkxRedirectFromApple && isOkxConnected === false) {
      redirectToExternalLink(createOkxConnectLink());
    }

    if (isBitgetRedirectFromApple && isBitgetConnected === false) {
      redirectToExternalLink(createBitgetConnectLink());
    }
  }, [
    isOkxRedirectFromApple,
    isOkxConnected,
    isBitgetRedirectFromApple,
    isBitgetConnected,
  ]);

  return (
    <main className="main details">
      <TrackTwitter />
      <DetailsSeoSetting account={accountName as string} />
      <h1 className="visually-hidden">Strategy details</h1>

      {isMainSkeletonsShown ? (
        <DetailsBlocksSkeletons />
      ) : (
        <>
          <DetailsBlocks1 />
          <DetailsBlocks2 />
        </>
      )}
    </main>
  );
}
