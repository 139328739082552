import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import LogoIcon from "../icons/logo-icon";
import OkxIcon from "../icons/okx-icon";
import PlusIcon from "../icons/sign/plus-icon";
import { setAuthModalOpened } from "../../store/actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createOkxConnectLink } from "../../utils/id";
import { trackEvent } from "../../utils/tracking";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  OKX_REGISTER_LINK,
} from "../../const";

export default function OkxPromo(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus, isOkxConnected } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const okxConnectLink = createOkxConnectLink();

  const handleRegisterClick = () => {
    trackEvent("OKXLandingRegisterAccount", "Clicked");
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("OKXLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Okx}/`);
  };

  return (
    <section className="landing__item okx-promo">
      <div className="okx-promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">
            Boost your trading performance
          </span>{" "}
          on <span className="landing__heading-large">OKX</span> with&nbsp;
          <span className="landing__heading-large">SMARD</span>
        </h1>
      </div>
      <p className="okx-promo__description">
        SMARD is the official partner and broker of OKX. This partnership
        allowed us to make our trading strategies more efficient and the overall
        user experience more convenient. Register your account with OKX to trade
        on the highly liquid exchange that supports our most efficient
        strategies.
      </p>
      <div className="okx-promo__icons">
        <span className="okx-promo__icon okx-promo__icon--smard">
          <LogoIcon />
        </span>
        <span className="okx-promo__icon okx-promo__icon--plus">
          <PlusIcon />
        </span>
        <span className="okx-promo__icon okx-promo__icon--okx">
          <OkxIcon />
        </span>
      </div>

      <div className="okx-promo__btns">
        {isOkxConnected === null ? (
          <div className="landing__btn-gap"></div>
        ) : isOkxConnected ? (
          <Button
            pattern="white-blue"
            text="View trading results"
            type="button"
            icon="eye"
            size="super"
            handleClick={handleViewClick}
          />
        ) : (
          <>
            <Button
              pattern="white-blue"
              text="Register an OKX account"
              icon="key-in-circle"
              type="link"
              size="super-big"
              linkAddress={OKX_REGISTER_LINK}
              handleClick={handleRegisterClick}
            />
            <Button
              pattern="blue-white"
              text="Connect an existing account"
              icon="check"
              type={isNoAuth ? "button" : "link"}
              size="huge"
              linkAddress={okxConnectLink}
              isSelfLink
              handleClick={handleConnectClick}
            />
          </>
        )}
      </div>
    </section>
  );
}
