export default function BlueDollarInDropIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fill="#60A5FA"
        d="M14 0C6.33 0 0 6.33 0 14s6.33 14 14 14 14-6.33 14-14S21.67 0 14 0Z"
      />
      <path
        fill="#2563EB"
        d="M28 14c0 7.67-6.33 14-14 14V0c7.67 0 14 6.33 14 14Z"
      />
      <path
        fill="#60A5FA"
        d="M22 16.21A7.9 7.9 0 0 1 14 24a7.9 7.9 0 0 1-8-7.79C6 11.91 14 4 14 4s8 7.91 8 12.21Z"
      />
      <path
        fill="#DCE7FA"
        d="M13.81 19.55v-1.23c-.44 0-.85-.05-1.25-.12-.4-.07-.72-.16-.97-.27v-.95a5.94 5.94 0 0 0 2.22.48v-2.59c-.5-.14-.92-.3-1.25-.49a2 2 0 0 1-.73-.69c-.16-.27-.25-.6-.25-.98 0-.4.1-.74.28-1.02.18-.28.44-.5.78-.67.33-.16.72-.25 1.17-.27V9.73h.7v1a5.3 5.3 0 0 1 2.03.45l-.3.82a6.06 6.06 0 0 0-1.73-.43v2.57c.5.15.93.31 1.27.49a2 2 0 0 1 .78.64c.18.25.26.58.26.98 0 .57-.2 1.04-.6 1.39-.41.35-.98.56-1.71.63v1.28h-.7Zm.7-2.15c.46-.04.81-.16 1.04-.34a.9.9 0 0 0 .34-.74c0-.22-.04-.4-.13-.54a1.05 1.05 0 0 0-.43-.37c-.2-.1-.48-.2-.82-.3v2.3Zm-.7-3.5v-2.3c-.28.02-.52.07-.72.16-.2.1-.34.2-.44.35a.92.92 0 0 0-.14.52c0 .23.04.43.13.58.08.15.22.29.41.4.2.1.45.2.76.28Z"
      />
      <mask
        id="a"
        width="8"
        height="20"
        x="6"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#D9D9D9"
          d="M6 16.21A7.9 7.9 0 0 0 14 24V4s-8 7.91-8 12.21Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#A0CBFF"
          d="M22 16.21A7.9 7.9 0 0 1 14 24a7.9 7.9 0 0 1-8-7.79C6 11.91 14 4 14 4s8 7.91 8 12.21Z"
        />
        <path
          fill="#DCE7FA"
          d="M13.81 19.55v-1.23c-.44 0-.85-.05-1.25-.12-.4-.07-.72-.16-.97-.27v-.95a5.94 5.94 0 0 0 2.22.48v-2.59c-.5-.14-.92-.3-1.25-.49a2 2 0 0 1-.73-.69c-.16-.27-.25-.6-.25-.98 0-.4.1-.74.28-1.02.18-.28.44-.5.78-.67.33-.16.72-.25 1.17-.27V9.73h.7v1a5.3 5.3 0 0 1 2.03.45l-.3.82a6.06 6.06 0 0 0-1.73-.43v2.57c.5.15.93.31 1.27.49a2 2 0 0 1 .78.64c.18.25.26.58.26.98 0 .57-.2 1.04-.6 1.39-.41.35-.98.56-1.71.63v1.28h-.7Zm.7-2.15c.46-.04.81-.16 1.04-.34a.9.9 0 0 0 .34-.74c0-.22-.04-.4-.13-.54a1.05 1.05 0 0 0-.43-.37c-.2-.1-.48-.2-.82-.3v2.3Zm-.7-3.5v-2.3c-.28.02-.52.07-.72.16-.2.1-.34.2-.44.35a.92.92 0 0 0-.14.52c0 .23.04.43.13.58.08.15.22.29.41.4.2.1.45.2.76.28Z"
        />
      </g>
    </svg>
  );
}
