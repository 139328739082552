import { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CoinsChart from "../../components/charts/coins-chart";
import CoinsSkeleton from "../../components/skeletons/coins-skeleton";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { CoinsSeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadTradableCoins, setFirstScreenRendered } from "../../store/actions";
import { debounce } from "../../utils/chart/chart";
import { TradableCoinType } from "../../types/coins";
import { fetchTradableCoins } from "../../store/api-actions";
import { deleteSeoData } from "../../utils/general";
import { checkIfDesktop } from "../../utils/breakpoint";
import {
  AppRoute,
  AuthStatus,
  CoinsSorting,
  RERENDER_DELAY,
} from "../../const";
import {
  checkPercentClass,
  displayAmount,
  displayArrow,
  displayMinus,
  displayPercent,
  displayPrice,
  displaySortArrows,
  handleSorting,
  sortByMCToLess,
} from "../../utils/component/coins";

export default function CoinsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    authStatus,
    isFirstScreenRendered,
    tradableCoins,
    isTradableCoinsLoading,
  } = useAppSelector((state) => state);

  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());
  const [coinsList, setCoinsList] = useState<TradableCoinType[]>([]);
  const [activeSorting, setActiveSorting] = useState<string>(
    CoinsSorting.DESC_MC
  );

  useEffect(() => {
    setCoinsList(tradableCoins.slice().sort(sortByMCToLess));
  }, [tradableCoins]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  useEffect(() => {
    dispatch(fetchTradableCoins());

    return () => {
      dispatch(loadTradableCoins([]));
    };
  }, [dispatch]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  const handleChangeSort = (evt: SyntheticEvent) => {
    handleSorting(
      evt,
      CoinsSorting.DESC_C24H,
      activeSorting,
      setActiveSorting,
      coinsList,
      setCoinsList
    );
  };

  const handleMarketCapSort = (evt: SyntheticEvent) => {
    handleSorting(
      evt,
      CoinsSorting.DESC_MC,
      activeSorting,
      setActiveSorting,
      coinsList,
      setCoinsList
    );
  };

  const handleVolumeSort = (evt: SyntheticEvent) => {
    handleSorting(
      evt,
      CoinsSorting.DESC_V24H,
      activeSorting,
      setActiveSorting,
      coinsList,
      setCoinsList
    );
  };

  return (
    <main className="main main--landing landing">
      <div className="landing__wrapper">
        <CoinsSeoSetting />
        <TrackTwitter />
        <h1 className="coins__heading">Tradable Coins</h1>
        {isTradableCoinsLoading ? (
          <CoinsSkeleton />
        ) : (
          coinsList.length !== 0 && (
            <ul className="coins__list">
              <li className="coins__row coins__row--heading">
                <div className="coins__wrapper-heading">
                  <p className="coins__row-heading coins__row-heading--coin">
                    Coin
                  </p>
                  <p className="coins__row-heading coins__row-heading--price">
                    Price
                  </p>
                  <div
                    className="coins__row-heading coins__row-heading--change"
                    onClick={handleChangeSort}
                  >
                    <span>24h Change</span>
                    {displaySortArrows(
                      activeSorting,
                      CoinsSorting.INC_C24H,
                      CoinsSorting.DESC_C24H
                    )}
                  </div>

                  <div
                    className="coins__row-heading coins__row-heading--market-cap"
                    onClick={handleMarketCapSort}
                  >
                    <span>Market Cap</span>
                    {displaySortArrows(
                      activeSorting,
                      CoinsSorting.INC_MC,
                      CoinsSorting.DESC_MC
                    )}
                  </div>
                  <div
                    className="coins__row-heading coins__row-heading--volume"
                    onClick={handleVolumeSort}
                  >
                    <span>24h Volume</span>
                    {displaySortArrows(
                      activeSorting,
                      CoinsSorting.INC_V24H,
                      CoinsSorting.DESC_V24H
                    )}
                  </div>
                  <p className="visually-hidden">Chart</p>
                </div>
              </li>
              {coinsList.map((item, index) => {
                return (
                  <li className="coins__row" key={index}>
                    <Link
                      className="coins__link"
                      to={`${AppRoute.Coins}${item.slug}/`}
                    >
                      <div className="coins__wrapper">
                        <div className="coins__instrument">
                          <img
                            className="coins__instrument-logo"
                            src={item.logo_url}
                            alt={`${item.title} logo`}
                          />
                          <div className="coins__insrument-wrapper">
                            <p className="coins__insrument-title shadow shadow--coins">
                              {item.title}
                            </p>
                            <p className="coins__insrument-ticker">
                              {item.ticker}
                            </p>
                          </div>
                        </div>

                        <div className="coins__price">
                          <p className="coins__price-amount">
                            {displayPrice(item.price)}
                          </p>
                          {!isDesktop && (
                            <p
                              className={`coins__change-amount
                      ${checkPercentClass(item.change_24h)}`}
                            >
                              {displayArrow(item.change_24h)}
                              {displayPercent(item.change_24h)}
                            </p>
                          )}
                        </div>

                        {isDesktop && (
                          <div className="coins__change">
                            <p
                              className={`coins__change-amount
                      ${checkPercentClass(item.change_24h)}`}
                            >
                              {displayArrow(item.change_24h)}
                              {displayPercent(item.change_24h)}
                            </p>
                          </div>
                        )}

                        <div className="coins__market-cap">
                          <p className="coins__title">Market Cap</p>
                          <p className="coins__market-cap-amount">
                            {displayMinus(item.market_cap)}
                            {displayAmount(item.market_cap)}
                          </p>
                        </div>

                        <div className="coins__volume">
                          <p className="coins__title">24h Volume</p>
                          <p className="coins__volume-amount">
                            {displayMinus(item.volume_24h)}
                            {displayAmount(item.volume_24h)}
                          </p>
                        </div>
                        <div className="coins__chart">
                          <CoinsChart data={item.quotes} />
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )
        )}
      </div>
    </main>
  );
}
