import BinanceCanvas from "../canvases/binance-canvas";
import Button from "../button/button";
import { AccountName } from "../../const";
import { QuotesItemsType } from "../../types/details";

type CompareBlocksProps = {
  binanceSpotQuotes: QuotesItemsType;
  binanceFuturesQuotes: QuotesItemsType;
};

export default function CompareBlocks({
  binanceSpotQuotes,
  binanceFuturesQuotes,
}: CompareBlocksProps): JSX.Element {
  return (
    <section className="landing__item compare-blocks">
      <h2 className="landing__title">Choose the one that suits you</h2>
      <div className="compare-blocks__wrapper">
        <div className="compare-blocks__item">
          <p className="compare-blocks__info">
            The strategy relies on a strong momentum effect. It aims to generate
            absolute returns in the cryptocurrency market by trading spot
            instruments. Most of the time, it carries zero risk to the market
            and stays in the base currency.
          </p>
          <div className="compare-blocks__chart">
            <BinanceCanvas
              type={AccountName.BinanceSpot}
              data={binanceSpotQuotes}
            />
          </div>
          <Button
            classElement="compare-blocks__btn"
            pattern="white-blue"
            text="Read whitepaper"
            icon="arrow-right"
            type="link"
            size="big"
            linkAddress={`${process.env.PUBLIC_URL}/documents/SMARD_Spot_Time_Series_Momentum.pdf`}
          />
        </div>
        <div className="compare-blocks__item">
          <p className="compare-blocks__info">
            This strategy also employs the momentum effect and aims to generate
            returns in bull and bear markets using futures contracts. This
            strategy constructs a portfolio of the 30 most liquid futures
            contracts and rebalances it on a periodic basis.
          </p>
          <div className="compare-blocks__chart">
            <BinanceCanvas
              type={AccountName.BinanceFutures}
              data={binanceFuturesQuotes}
            />
          </div>
          <Button
            classElement="compare-blocks__btn"
            pattern="white-blue"
            text="Read whitepaper"
            icon="arrow-right"
            type="link"
            size="big"
            linkAddress={`${process.env.PUBLIC_URL}/documents/SMARD_Futures_Flexible_Momentum.pdf`}
          />
        </div>
      </div>
    </section>
  );
}
