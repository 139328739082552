export default function BlueDollarArrowsRightIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 28 28"
    >
      <g clipPath="url(#a)">
        <path
          fill="#60A5FA"
          d="M12.3 12.72c-1.22-.6-2.5-1.07-3.78-1.55a8.75 8.75 0 0 1-2.07-1.05c-1.23-.9-1-2.34.44-2.91.41-.16.84-.22 1.27-.24 1.67-.08 3.26.2 4.77.86.75.33 1 .22 1.26-.49.27-.75.49-1.51.74-2.27.16-.51-.04-.85-.57-1.06-.97-.39-1.96-.66-3.01-.82C9.99 3 9.99 3 9.98 1.76 9.98 0 9.98 0 8.03 0H7.2c-.9.03-1.06.17-1.08 1V2.1c-.01 1.1-.02 1.08-1.18 1.47C2.13 4.5.39 6.24.2 9c-.16 2.46 1.25 4.12 3.48 5.33 1.37.74 2.89 1.18 4.34 1.77.57.22 1.11.48 1.58.84C11 18 10.74 19.74 9.1 20.4a5.7 5.7 0 0 1-2.8.33 13.4 13.4 0 0 1-4.24-1.15c-.78-.37-1.01-.27-1.28.5-.23.66-.43 1.33-.64 2-.27.9-.17 1.12.78 1.54 1.21.53 2.5.8 3.83 1 1.03.14 1.06.19 1.07 1.16l.02 1.32c0 .56.3.88.93.9l2.15-.01c.58-.02.88-.3.88-.84 0-.6.04-1.21.01-1.81-.03-.62.26-.93.91-1.09a7.32 7.32 0 0 0 3.76-2.19c2.73-3 1.69-7.4-2.18-9.34Z"
        />
        <path
          fill="#2563EB"
          fillRule="evenodd"
          d="M9.1 11.33c1.1.43 2.21.85 3.27 1.39 3.79 1.94 4.8 6.34 2.13 9.34a7.15 7.15 0 0 1-3.68 2.19c-.64.16-.93.47-.9 1.09.02.38.01.77 0 1.16v.65c0 .54-.3.82-.87.84h-.9v-7.3c.37-.05.72-.15 1.07-.3 1.63-.65 1.88-2.4.5-3.44a6.46 6.46 0 0 0-1.56-.85v-5.14a13.57 13.57 0 0 0 .93.37Zm-.94-4.35h.16c1.63-.09 3.2.19 4.67.85.74.33.98.22 1.23-.49.17-.48.32-.96.47-1.45l.26-.82c.16-.51-.04-.85-.56-1.06-.95-.39-1.92-.66-2.95-.82C10.1 3 10.1 3 10.1 1.75c0-.85 0-1.29-.23-1.51C9.64 0 9.17 0 8.2 0h-.03v6.98ZM7.67 0h-.06.06Z"
          clipRule="evenodd"
        />
        <path
          fill="#2563EB"
          d="M21 15.75v-4.5h2.71V9L28 13.5 23.71 18v-2.25H21ZM17.6 23.77l3.17-3.18 1.92 1.92 1.6-1.6-.16 6.22-6.21.15 1.59-1.59-1.92-1.92ZM20.77 6.72 17.6 3.54l1.92-1.92L17.92.03l6.21.15.15 6.21L22.7 4.8l-1.92 1.92Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
