import { useEffect } from "react";

import Bots from "../../components/bots/bots";
import BybitFaq from "../../components/faq/bybit-faq";
import BybitPluses from "../../components/pluses/bybit-pluses";
import BybitSteps from "../../components/steps/bybit-steps";
import BybitPromo from "../../components/promos/bybit-promo";
import BybitInsight from "../../components/insights/bybit-insight";
import BybitBriefly from "../../components/brieflies/bybit-briefly";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteSeoData } from "../../utils/general";
import { BybitLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { fetchBybitDemo } from "../../store/api-actions/demo";

export default function BybitScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const { bybitDemo } = useAppSelector((state) => state);

  // any page
  useEffect(() => {
    trackEvent("BybitLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  useEffect(() => {
    dispatch(fetchBybitDemo());
  }, [dispatch]);

  return (
    <main className="main main--landing landing">
      <BybitLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <BybitPromo />
        <BybitPluses />
        <BybitSteps />
        {bybitDemo && <BybitInsight />}
        <BybitBriefly />
        <Bots />
        <BybitFaq />
      </div>
    </main>
  );
}