import TrendingUpIcon from "../../../icons/trending/trending-up-icon";
import TrendingDownIcon from "../../../icons/trending/trendinf-down-icon";
import { StrategyType } from "../../../types/strategy";
import { showDateWithShortYear } from "../../../utils/datetime";
import {
  deleteMinusIfNegative,
  formatStrategyTrending,
  localAmount,
} from "../../../utils/amount";

type OverviewTrendingProps = {
  overview: StrategyType;
};

export default function OverviewTrending({
  overview,
}: OverviewTrendingProps): JSX.Element {
  const percentProfit = overview.profit_last_30_days_percent_chart;
  const date = overview.last_30_days_timestamp;

  const showText = () => {
    if (percentProfit !== null && date) {
      return `${localAmount(
        deleteMinusIfNegative(formatStrategyTrending(percentProfit).amount)
      )}${
        formatStrategyTrending(percentProfit).short
      }% since ${showDateWithShortYear(date)}`;
    }
  };

  //   Вставить иконку!
  return percentProfit !== null && date ? (
    <span className="overview-board__trending">
      {formatStrategyTrending(percentProfit).amount >= 0 ? (
        <span className="overview-board__trending-icon overview-board__trending-icon--up">
          <TrendingUpIcon />
        </span>
      ) : (
        <span className="overview-board__trending-icon overview-board__trending-icon--down">
          <TrendingDownIcon />
        </span>
      )}
      {showText()}
    </span>
  ) : (
    <></>
  );
}
