import TrendingDownIcon from "../../icons/trending/trendinf-down-icon";
import TrendingUpIcon from "../../icons/trending/trending-up-icon";
import { useAppSelector } from "../../hooks";
import { StrategyResultsType } from "../../types/details";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

type DeversifyResultsProps = {
  isBitcoinMode: boolean;
};

export default function DeversifyResults({
  isBitcoinMode,
}: DeversifyResultsProps): JSX.Element {
  const { binanceSpotDemo, binanceFuturesDemo, diversifiedDemo } =
    useAppSelector((state) => state);

  const binanceSpotResults =
    binanceSpotDemo?.strategy_results as StrategyResultsType;
  const binanceFuturesResults =
    binanceFuturesDemo?.strategy_results as StrategyResultsType;
  const deversifiedResults =
    diversifiedDemo?.strategy_results as StrategyResultsType;

  const titleRow = (
    <li className="deversify-results__row">
      <p className="deversify-results__title">Metrics</p>
      <p className="deversify-results__title">Binance Spot</p>
      <p className="deversify-results__title">Binance Futures</p>
      <p className="deversify-results__title">Diversified portfolio</p>
    </li>
  );

  return (
    <div className="deversify-results">
      <h2 className="deversify-results__heading">Historical results</h2>
      <ul className="deversify-results__table">
        {titleRow}
        {/* SHARPE RATIO */}
        <li className="deversify-results__row">
          <p className="deversify-results__cell">Sharpe ratio</p>
          <p className="deversify-results__cell">
            {displayResultsAmount(binanceSpotResults.sharpe_ratio)}
          </p>
          <p className="deversify-results__cell">
            {displayResultsAmount(binanceFuturesResults.sharpe_ratio)}
          </p>
          <p className="deversify-results__cell">
            {displayResultsAmount(deversifiedResults.sharpe_ratio)}
            {deversifiedResults.sharpe_ratio !== null &&
              deversifiedResults.sharpe_ratio !== undefined && (
                <span className="deversify-results__icon">
                  <TrendingUpIcon />
                </span>
              )}
          </p>
        </li>
        {/* MAX DRAWDOWN */}
        <li className="deversify-results__row">
          <p className="deversify-results__cell">Max drawdown</p>
          <p className="deversify-results__cell">
            {displayResultsPercent(binanceSpotResults.mdd)}
          </p>
          <p className="deversify-results__cell">
            {displayResultsPercent(binanceFuturesResults.mdd)}
          </p>
          <p className="deversify-results__cell">
            {displayResultsPercent(deversifiedResults.mdd)}
            {deversifiedResults.mdd !== null &&
              deversifiedResults.mdd !== undefined && (
                <span className="deversify-results__icon">
                  <TrendingDownIcon />
                </span>
              )}
          </p>
        </li>
        {/* RETURN VS BITCON */}
        <li className="deversify-results__row">
          <p className="deversify-results__cell">{`Return vs ${
            isBitcoinMode ? "BTC" : "ETH"
          }`}</p>
          <p className="deversify-results__cell">
            {isBitcoinMode
              ? displayResultsPercent(binanceSpotResults.return_vs_btc)
              : displayResultsPercent(binanceSpotResults.return_vs_eth)}
          </p>
          <p className="deversify-results__cell">
            {isBitcoinMode
              ? displayResultsPercent(binanceFuturesResults.return_vs_btc)
              : displayResultsPercent(binanceFuturesResults.return_vs_eth)}
          </p>
          <p className="deversify-results__cell">
            {isBitcoinMode
              ? displayResultsPercent(deversifiedResults.return_vs_btc)
              : displayResultsPercent(deversifiedResults.return_vs_eth)}
          </p>
        </li>
      </ul>
    </div>
  );
}
